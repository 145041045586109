<div
  class="flex h-full w-full items-center justify-center overflow-hidden rounded-m {{
    path !== '' ? '' : 'bg-' + colors[hashStringToNumber(name) % 12]
  }}"
  [ngClass]="{
    'border-[1.6px]': border
  }"
  [class.border-neutral100]="border"
>
  <img crossorigin [src]="path" *ngIf="path !== '' && path !== '-'" />
  <div
    class="select-none text-label-m text-neutral100"
    *ngIf="(path === '' || path === '-') && name.length > 0"
  >
    {{ name[0] }}
  </div>
</div>

import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  @Input() datas: any[] = [];
  @Input() columns: any[] = [];
  @Input() tabs: any[] = [];
  @Input() defaultButton = '';
  @Input() deleteButton = '';
  @Input() options: any = {
    rowClick: null,
  };
  @Input() tableEvent: EventEmitter<any> | null = null;

  @Output() event = new EventEmitter();
  sanitizer = inject(DomSanitizer);

  page = 1;
  pageSize = 10;
  collectionSize = 1;

  tabCounts: any[] = [];
  currentTab = 0;

  sortTarget: any = null;
  sortOrder = false;

  checkAll = false;
  origDatas: any[] = [];
  displayDatas: any[] = [];

  checkCount = 0;

  pages: any[] = [];

  ngOnInit() {
    console.log('table', this);
  }

  ngOnChanges() {
    this.origDatas = this.datas;
    let i = 0;
    for (let data of this.origDatas) {
      data.idx = ++i;
      for (let column of this.columns) {
        if (column.data) {
          data[column.name] = column.data(data);
        }
      }
    }

    if (this.tableEvent) {
      this.tableEvent.subscribe((e) => {
        if (e.name === 'check') {
          this.updateCount();
        }
      });
    }

    this.displayDatas = this.origDatas;
    this.processData();
  }

  updateCount() {
    let count = 0;
    for (let data of this.origDatas) {
      if (data.checked) {
        count += 1;
      }
    }
    this.checkCount = count;
    console.log('HI', count, this.origDatas);
  }

  setTab(target: number) {
    this.currentTab = target;
    this.processData();
  }

  toggleCheckAll() {
    this.checkAll = !this.checkAll;
    for (let data of this.origDatas) {
      if (typeof data.canCheck !== 'undefined' && !data.canCheck) {
      } else {
        data.checked = this.checkAll;
      }
    }
    this.updateCount();
  }

  sortColumn(target: any) {
    if (target.sortable) {
      if (this.sortTarget === target) {
        this.sortOrder = !this.sortOrder;
      } else {
        this.sortOrder = false;
        this.sortTarget = target;
      }
      this.processData();
    }
  }

  setPage(target: any) {
    let maxPage = Math.floor((this.collectionSize - 1) / this.pageSize) + 1;
    if (target < 1) target = 1;
    if (target > maxPage) target = maxPage;
    this.page = target;
    this.processData();
  }

  processData() {
    this.displayDatas = this.origDatas;
    this.collectionSize = this.displayDatas.length;
    if (this.sortTarget) {
      this.displayDatas = this.displayDatas.sort((a: any, b: any) => {
        return a.idx - b.idx;
      });

      this.displayDatas = this.displayDatas.sort((a: any, b: any) => {
        let value = 0;

        let v1: any = a[this.sortTarget.id];
        let v2: any = b[this.sortTarget.id];

        if (typeof this.sortTarget['sortData'] !== 'undefined') {
          v1 = this.sortTarget['sortData'](a, this.sortOrder);
          v2 = this.sortTarget['sortData'](b, this.sortOrder);
        } else if (typeof this.sortTarget['data'] !== 'undefined') {
          v1 = this.sortTarget['data'](a);
          v2 = this.sortTarget['data'](b);
        }

        if (typeof v1 === 'number') {
          value = v2 - v1;
        } else if (typeof v1 === 'string') {
          value = v2 > v1 ? 1 : -1;

          if (v1 === '') {
            if (v2 === '-%-') {
              return -1;
            } else {
              return 1;
            }
          }
          if (v2 === '') {
            if (v1 === '-%-') {
              return 1;
            } else {
              return -1;
            }
          }
          if (v1 === '-%-') {
            return 1;
          }
          if (v2 === '-%-') {
            return -1;
          }
        }

        if (
          typeof a.email !== 'undefined' &&
          a.email === '-' &&
          typeof b.email !== 'undefined' &&
          b.email === '-'
        ) {
          return value * (this.sortOrder ? 1 : -1);
        }

        if (typeof a.email !== 'undefined' && a.email === '-') {
          return 1;
        }

        if (typeof b.email !== 'undefined' && b.email === '-') {
          return -1;
        }

        return value * (this.sortOrder ? 1 : -1);
      });
    }

    this.checkAll = false;
    this.checkCount = 0;
    for (let data of this.origDatas) {
      data.checked = false;
    }

    this.tabCounts = [];
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabCounts.push(0);
    }

    for (let data of this.origDatas) {
      if (data.types) {
        for (let type of data.types) {
          this.tabCounts[type] += 1;
        }
      }
    }

    let newArr: any = [];
    if (this.currentTab > 0) {
      for (let data of this.displayDatas) {
        if (data.types) {
          if (data.types.indexOf(this.currentTab - 1) >= 0) {
            newArr.push(data);
          }
        }
      }
      this.displayDatas = newArr;
    }

    let maxPage =
      Math.floor((this.displayDatas.length - 1) / this.pageSize) + 1;
    this.pages = [];
    for (let i = 1; i <= maxPage; i++) {
      this.pages.push(i);
    }
    if (this.page <= 5) {
      this.pages = this.pages.slice(0, 10);
    } else if (this.page > maxPage - 5) {
      this.pages = this.pages.slice(maxPage - 10, maxPage);
    } else {
      this.pages = this.pages.slice(this.page - 5, this.page + 5);
    }
  }

  clickColumn(column: any, data: any, e: any) {
    e.preventDefault();
    column.click(data);
  }
}

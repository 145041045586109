import { EventEmitter, Injectable, inject } from '@angular/core';
import { AjaxService } from './ajax.service';
//import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import { ratingItems } from '../rating';
import { UtilService } from './util.service';
import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  event = new EventEmitter();
  events = {
    topbar: new EventEmitter(),
    alert: new EventEmitter(),
  };

  cb: any = {
    updateInterviewers: async (data: any) => {
      await this.updateInterviewers();
    },
  };

  configs: any = {};

  client: any = {
    id: -1,
    name: '',
    profile: '',
    depts: [],
    jobs: [],
    interviewers: [],
    steps: [],
  };

  objs: any = {
    depts: {},
    jobs: {},
    interviewers: {},
    steps: {},
  };

  interviewer: any = {
    id: -1,
    // client: 1,
    // name: '순조O',
    // level: 4,
    // dept: 1,
    // email: 'prena@prena.co.kr',
    // phone: '01012345671',
    // voice: 1,
    // profile: 'assets/imgs/logo.png',
  };

  id = -1;

  ajax = inject(AjaxService);
  util = inject(UtilService);
  socket = inject(Socket);

  loaded = false;

  token = '';

  toast: any = null;
  toastTimer: any = null;

  constructor() {
    console.log('client', this);
    this.load();
  }

  async load() {
    if (this.id === -1) {
      return;
    }
    await this.updateClient();
    this.socket.emit('joinRoom', 'interviewer' + this.interviewer.id);

    this.loaded = true;
  }

  async waitLoad() {
    if (this.loaded) return true;
    return new Promise((resolve) => {
      let t = setInterval(() => {
        if (this.loaded) {
          clearInterval(t);
          resolve(true);
        }
      }, 1000 / 60);
    });
  }

  arrayToObj(arr: any) {
    let data: any = {};
    for (let d of arr) {
      data[d.id] = d;
    }
    return data;
  }

  async updateClient() {
    const r = await this.ajax.post('client/getClient', {
      token: this.token,
    });

    if (r.data.client) {
      this.client = r.data.client;
      await this.updateConfigs();
      await this.updateDepts();
      await this.updateJobs();
      await this.updateInterviewers();
      await this.updateSteps();

      this.socket.emit('joinRoom', 'client' + this.client.id);
      this.socket.on('updateInterviewers', this.cb.updateInterviewers);

      datadogRum.setUser({
        id: this.interviewer.id,
        client: this.id,
        name: this.interviewer.name,
        level: this.interviewer.level,
        email: this.interviewer.email,
      });
    }
  }

  async updateConfigs() {
    const configs = (
      await this.ajax.post('client/getConfig', { token: this.token })
    ).data.configs;
    this.configs = {};
    for (let config of configs) {
      this.configs[config.name] = config.value;
    }
  }

  async updateDepts() {
    const depts = (
      await this.ajax.post('client/getDepts', {
        token: this.token,
      })
    ).data.depts;
    this.client.depts = depts;
    this.objs.depts = this.util.arrayToObj(this.client.depts);
    this.event.emit({
      id: 'updateDepts',
    });
  }

  async updateJobs() {
    const jobs = (
      await this.ajax.post('client/getJobs', {
        token: this.token,
      })
    ).data.jobs;
    this.client.jobs = jobs;
    this.objs.jobs = this.util.arrayToObj(this.client.jobs);
    this.event.emit({
      id: 'updateJobs',
    });
  }

  async updateInterviewers() {
    const interviewers = (
      await this.ajax.post('client/getInterviewers', {
        token: this.token,
      })
    ).data.interviewers;
    this.client.interviewers = interviewers;
    for (let data of this.client.interviewers) {
      try {
        data.voices = JSON.parse(data.voices);
      } catch (e) {
        data.voices = [];
      }
    }
    this.objs.interviewers = this.util.arrayToObj(this.client.interviewers);
    this.event.emit({
      id: 'updateInterviewers',
    });
  }

  async updateSteps() {
    const steps = (
      await this.ajax.post('client/getSteps', { token: this.token })
    ).data.steps;
    this.client.steps = steps;
    this.objs.steps = this.util.arrayToObj(steps);
    this.event.emit({
      id: 'updateSteps',
    });
  }

  getDept(id: number, target: string = '') {
    if (typeof this.objs.depts[id] === 'undefined') {
      return '-';
    } else {
      if (target === '') {
        return this.objs.depts[id];
      } else {
        return this.objs.depts[id][target];
      }
    }
  }

  getJob(id: number, target: string = '') {
    if (typeof this.objs.jobs[id] === 'undefined') {
      return '-';
    } else {
      if (target === '') {
        return this.objs.jobs[id];
      } else {
        return this.objs.jobs[id][target];
      }
    }
  }

  getInterviewer(id: number, target: string = '') {
    if (typeof this.objs.interviewers[id] === 'undefined') {
      return '-';
    } else {
      if (target === '') {
        return this.objs.interviewers[id];
      } else {
        return this.objs.interviewers[id][target];
      }
    }
  }

  getStep(id: number, target: string = '') {
    if (typeof this.objs.steps[id] === 'undefined') {
      if (id === 0 && target === 'o') {
        return -1;
      } else if (id === -1 && target === 'o') {
        return 9999;
      }
      if (id === 0 && target === 'name') {
        return this.client.defaultStep;
      } else if (id === -1 && target === 'name') {
        return '면접 합격';
      } else {
        return '-';
      }
    } else {
      if (target === '') {
        return this.objs.steps[id];
      } else {
        return this.objs.steps[id][target];
      }
    }
  }

  async deleteInterview(id: number) {
    await this.ajax.post('i/deleteInterview', {
      token: this.token,
      interview: id,
    });
  }

  setToast(data: any, time: number = 5000) {
    clearTimeout(this.toastTimer);
    setTimeout(() => {
      if (typeof data.bg !== 'undefined' && data.bg === 'red') {
        data.icon ??=
          '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.0938 20.3203C9.07031 20.3203 8.47656 19.9688 7.91406 19.3438L4.91406 15.9766C4.35156 15.3438 4.21094 14.9297 4.21094 14.0703V10.6484C4.21094 9.78906 4.35156 9.38281 4.91406 8.74219L7.91406 5.38281C8.47656 4.75781 9.07031 4.39844 10.0938 4.39844H14.9062C15.9297 4.39844 16.5312 4.75 17.0859 5.38281L20.0234 8.74219C20.6016 9.41406 20.7891 9.78906 20.7891 10.6484V14.0703C20.7891 14.9297 20.6406 15.3438 20.0781 15.9766L17.0859 19.3438C16.5234 19.9688 15.9297 20.3203 14.9062 20.3203H10.0938ZM12.5078 13.6328C12.9922 13.6328 13.2734 13.3594 13.2969 12.8516L13.4141 9.16406C13.4375 8.64062 13.0469 8.26562 12.5 8.26562C11.9453 8.26562 11.5625 8.63281 11.5859 9.15625L11.7031 12.8516C11.7266 13.3594 12.0078 13.6328 12.5078 13.6328ZM12.5078 16.3828C13.0703 16.3828 13.5234 16 13.5234 15.4453C13.5234 14.8984 13.0781 14.5078 12.5078 14.5078C11.9375 14.5078 11.4844 14.8984 11.4844 15.4453C11.4844 15.9922 11.9453 16.3828 12.5078 16.3828Z" fill="white"/></svg>';
      }
      data.icon ??=
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 20.5078C7.53125 20.5078 3.85156 16.8281 3.85156 12.3594C3.85156 7.89844 7.53125 4.21094 11.9922 4.21094C16.4609 4.21094 20.1484 7.89844 20.1484 12.3594C20.1484 16.8281 16.4688 20.5078 12 20.5078ZM11.1328 16.2422C11.4453 16.2422 11.7188 16.0859 11.9062 15.8047L15.5547 10.1719C15.6719 9.99219 15.7656 9.78125 15.7656 9.59375C15.7656 9.15625 15.375 8.85156 14.9531 8.85156C14.6797 8.85156 14.4453 9.00781 14.2578 9.29688L11.1094 14.3281L9.66406 12.5156C9.46094 12.2656 9.25781 12.1641 9 12.1641C8.5625 12.1641 8.21875 12.5156 8.21875 12.9531C8.21875 13.1641 8.28906 13.3516 8.44531 13.5469L10.3281 15.8125C10.5625 16.1016 10.8125 16.2422 11.1328 16.2422Z" fill="white"/></svg>';
      data.bg ??= 'green';
      data.click ??= () => {
        this.clearToast();
      };
      this.toast = data;
      this.toastTimer = setTimeout(() => {
        this.toast = -1;
      }, time);
    }, 0);
  }

  clearToast() {
    clearTimeout(this.toastTimer);
    this.toast = null;
  }

  async downInterview(interviewId: any) {
    const r = await this.ajax.post('i/getInterview', {
      token: this.token,
      interview: interviewId,
    });

    const data = r.data.interview;

    console.log(data);

    data.checked = false;
    data.files = JSON.parse(data.files);
    data.interviewers = JSON.parse(data.interviewers);
    data.rating = JSON.parse(data.rating);
    data.interviewersName = '-';
    if (data.interviewers.length > 0) {
      let names = [];
      for (let interviewer of data.interviewers) {
        names.push(this.getInterviewer(interviewer, 'name'));
      }
      data.interviewersName = names.join(',\n');
    }

    //data.interviewers = data.interviewers.splice(0, 3);

    data.types = [];
    if (data.status <= 0) {
      if (
        data.status === 0 &&
        (!data.timeSet || data.interviewers.length === 0)
      ) {
        data.types.push(0);
      } else {
        data.types.push(1);
      }
    } else if (data.status === 1 || data.status === 2) {
      data.types.push(2);
    } else if (data.status === 3) {
      data.types.push(3);
    } else if (data.status === 4) {
      data.types.push(4);
    } else if (data.status === 5) {
      data.types.push(5);
    }

    data['면접 전형'] = this.getStep(data.step, 'name');
    data['직무 부서'] = this.getDept(data.dept, 'name');
    data['직무'] = this.getJob(data.job, 'name');

    let after_all = '';
    try {
      data['findeet_after'] = JSON.parse(data['findeet_after']);
      after_all = data['findeet_after'].all;
    } catch (e) {}

    let keyword = '';
    try {
      data['findeet_pre'] = JSON.parse(data['findeet_pre']);
      keyword = data['findeet_pre'].keywords;
    } catch (e) {}

    const workbook = XLSX.utils.book_new();

    const reportArr = [];
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push([
      '',
      'Individual Interview Report',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    reportArr.push([
      '',
      '출력 일자 : ' + dayjs().format('YYYY-MM-DD HH:mm'),
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '1. 면접 정보', '', '', '', '', '', '', '']);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push([
      '',
      '고유 번호',
      '전형',
      '부서',
      '직무',
      '지원자명',
      '이메일',
      '',
      '',
    ]);
    reportArr.push([
      '',
      data.ino,
      data['면접 전형'],
      data['직무 부서'],
      data['직무'],
      data.name,
      data.email,
      '',
      '',
    ]);
    reportArr.push([
      '',
      '일자',
      '시간',
      '장소',
      '면접관',
      '담당자',
      '면접 내용 전체 보기',
      '',
      '',
    ]);
    reportArr.push([
      '',
      data.timeSet === 0 ? '-' : data.time1,
      data.timeSet === 0 ? '-' : data.time2,
      data.place,
      data.interviewersName,
      '-',
      'https://findeet.io/interview/' + data.id,
      '',
      '',
    ]);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '2. 면접 내용', '', '', '', '', '', '', '']);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '1) 면접 내용 요약', '', '', '', '', '', '', '']);
    reportArr.push(['', after_all, '', '', '', '', '', '', '']);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '2) 지원자 보유 역량', '', '', '', '', '', '', '']);
    reportArr.push(['', keyword, '', '', '', '', '', '', '']);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '3. 평가 결과', '', '', '', '', '', '', '']);
    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push([
      '',
      '1) Competency Assessment',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);

    let caCount = Math.floor((data.interviewers.length - 1) / 3) + 1;
    console.log('caCount', caCount);

    let allScoreSum = 0;

    let scores: any = [];
    let scoreCounts = [];

    let ratingDatas: any = [];

    for (let z = 0; z < caCount; z++) {
      let ratingHead = ['', '역량'];

      for (let idx = 0; idx < 3; idx++) {
        if (data.interviewers.length > z * 3 + idx) {
          ratingHead.push(
            this.getInterviewer(data.interviewers[z * 3 + idx], 'name'),
          );
        } else {
          ratingHead.push('');
        }
        ratingHead.push('');
      }

      ratingHead.push('');
      reportArr.push(ratingHead);

      let ratingHead2 = ['', '항목'];

      for (let idx = 0; idx < 3; idx++) {
        if (data.interviewers.length > z * 3 + idx) {
          ratingHead2.push('점수');
          ratingHead2.push('근거');
        } else {
          ratingHead2.push('');
          ratingHead2.push('');
        }
      }

      ratingHead2.push('');
      reportArr.push(ratingHead2);

      ratingDatas = [];

      for (let i = 0; i < 3; i++) {
        for (let rating of data.rating[i]) {
          ratingDatas.push(['', ratingItems[i].items[rating].name]);
        }
      }

      let ratingResults: any = {};

      for (let i = 0; i < 3; i++) {
        if (data.interviewers.length > z * 3 + i) {
          let interviewer = data.interviewers[z * 3 + i];
          const r = await this.ajax.post('e/downInterviewData', {
            i: data.id,
            iv: interviewer,
          });
          const res = r.data.ratings;
          if (res.length > 0) {
            ratingResults[interviewer] = JSON.parse(res[0].data);
          }
        }
      }

      for (let interviewer of data.interviewers) {
        scores.push(0);
        scoreCounts.push(0);
      }

      let ratingIdx = 0;
      for (let ratingData of ratingDatas) {
        for (let i = 0; i < 3; i++) {
          if (data.interviewers.length > z * 3 + i) {
            let interviewer = data.interviewers[z * 3 + i];
            if (typeof ratingResults[interviewer] === 'undefined') {
              ratingData.push('');
              ratingData.push('');
            } else {
              ratingData.push(ratingResults[interviewer][ratingIdx].score);
              ratingData.push(ratingResults[interviewer][ratingIdx].text);
              scores[z * 3 + i] += ratingResults[interviewer][ratingIdx].score;
              scoreCounts[z * 3 + i] += 1;
            }
          } else {
            ratingData.push('');
            ratingData.push('');
          }
        }
        ratingData.push('');
        ratingIdx += 1;
      }

      for (let ratingData of ratingDatas) {
        reportArr.push(ratingData);
      }
      reportArr.push(['', '', '', '', '', '', '', '', '']);
    }

    reportArr.push(['', '', '', '', '', '', '', '', '']);
    reportArr.push(['', '2) Overall Assessment', '', '', '', '', '', '', '']);

    let allScore = 0;

    let allScoreCount = 0;

    for (let i = 0; i < data.interviewers.length; i++) {
      if (scoreCounts[i] > 0) {
        allScore += scores[i] / scoreCounts[i];
        allScoreCount += 1;

        scores[i] = (scores[i] / scoreCounts[i]).toFixed(1);
      } else {
        scores[i] = '';
      }
    }

    if (allScoreCount > 0) {
      allScore /= allScoreCount;
    }

    for (let z = 0; z < caCount; z++) {
      reportArr.push([
        '',
        '항목',
        '종합 점수',
        data.interviewers.length > z * 3 + 0
          ? this.getInterviewer(data.interviewers[z * 3 + 0], 'name')
          : '',
        data.interviewers.length > z * 3 + 1
          ? this.getInterviewer(data.interviewers[z * 3 + 1], 'name')
          : '',
        data.interviewers.length > z * 3 + 2
          ? this.getInterviewer(data.interviewers[z * 3 + 2], 'name')
          : '',
        'Overall Comment',
        '',
        '',
      ]);
      reportArr.push([
        '',
        '평점\n(Ratings)',
        allScore.toFixed(1),
        scores[z * 3 + 0],
        scores[z * 3 + 1],
        scores[z * 3 + 2],
        '',
        '',
        '',
      ]);
      reportArr.push(['', '', '', '', '', '', '', '', '']);
    }

    reportArr.push(['', '', '', '', '', '', '', '', '']);

    console.log(reportArr);

    const worksheet_report: any = XLSX.utils.aoa_to_sheet(reportArr);
    let iIdx = 2;
    worksheet_report['!merges'] = [];
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B2:H2'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B3:H3'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B5:H5'));

    worksheet_report['!merges'].push(XLSX.utils.decode_range('G7:H7'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('G8:H8'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('G9:H9'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('G10:H10'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B12:H12'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B14:H14'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B15:H15'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B17:H17'));
    worksheet_report['!merges'].push(XLSX.utils.decode_range('B18:H18'));

    worksheet_report['!merges'].push(XLSX.utils.decode_range('B23:H23'));

    for (let z = 0; z < caCount; z++) {
      worksheet_report['!merges'].push(
        XLSX.utils.decode_range(
          `C${24 + z * (ratingDatas.length + 3)}:D${24 + z * (ratingDatas.length + 3)}`,
        ),
      );
      worksheet_report['!merges'].push(
        XLSX.utils.decode_range(
          `E${24 + z * (ratingDatas.length + 3)}:F${24 + z * (ratingDatas.length + 3)}`,
        ),
      );
      worksheet_report['!merges'].push(
        XLSX.utils.decode_range(
          `G${24 + z * (ratingDatas.length + 3)}:H${24 + z * (ratingDatas.length + 3)}`,
        ),
      );
    }

    worksheet_report['!merges'].push(
      XLSX.utils.decode_range(
        `B${28 + (ratingDatas.length + 3) * caCount - 3}}:H${28 + (ratingDatas.length + 3) * caCount - 3}`,
      ),
    );

    for (let z = 0; z < caCount; z++) {
      worksheet_report['!merges'].push(
        XLSX.utils.decode_range(
          `G${29 + (ratingDatas.length + 3) * caCount - 3 + z * 3}:H${29 + (ratingDatas.length + 3) * caCount - 3 + z * 3}`,
        ),
      );
      worksheet_report['!merges'].push(
        XLSX.utils.decode_range(
          `G${30 + (ratingDatas.length + 3) * caCount - 3 + z * 3}:H${30 + (ratingDatas.length + 3) * caCount - 3 + z * 3}`,
        ),
      );
    }

    worksheet_report['!rows'] = [];
    worksheet_report['!rows'][1] = { hpx: 43 };
    worksheet_report['!rows'][14] = { hpx: 400 };

    worksheet_report['B2'].s = {
      alignment: {
        horizontal: 'center',
        vertical: 'center',
      },
      font: {
        bold: true,
        sz: 16,
      },
      fill: {
        fgColor: {
          rgb: 'EFEFEF',
        },
      },
    };
    worksheet_report['B3'].s = {
      alignment: {
        horizontal: 'right',
      },
    };

    worksheet_report['B5'].s = {
      font: {
        bold: true,
        sz: 13,
      },
    };
    worksheet_report['B12'].s = {
      font: {
        bold: true,
        sz: 13,
      },
    };
    worksheet_report['B21'].s = {
      font: {
        bold: true,
        sz: 13,
      },
    };

    console.log('worksheet_report', worksheet_report);

    for (let i = 'B'.charCodeAt(0); i <= 'H'.charCodeAt(0); i++) {
      let c = String.fromCharCode(i);
      console.log(c);
      worksheet_report[c + '7'].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: 'EFEFEF',
          },
        },
      };
      worksheet_report[c + '9'].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: 'EFEFEF',
          },
        },
      };

      for (let z = 0; z < caCount; z++) {
        worksheet_report[c + `${25 + z * (ratingDatas.length + 3)}`].s = {
          font: {
            bold: true,
          },
          fill: {
            fgColor: {
              rgb: 'EFEFEF',
            },
          },
        };
      }

      for (let z = 0; z < caCount; z++) {
        worksheet_report[
          c + (29 + (ratingDatas.length + 3) * caCount - 3 + z * 3)
        ].s = {
          font: {
            bold: true,
          },
          fill: {
            fgColor: {
              rgb: 'EFEFEF',
            },
          },
        };
      }
    }

    for (let z = 0; z < caCount; z++) {
      worksheet_report[`B${24 + z * (ratingDatas.length + 3)}`].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: 'EFEFEF',
          },
        },
      };
      worksheet_report[`C${24 + z * (ratingDatas.length + 3)}`].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: 'EFEFEF',
          },
        },
      };
      worksheet_report[`E${24 + z * (ratingDatas.length + 3)}`].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: 'EFEFEF',
          },
        },
      };
      worksheet_report[`G${24 + z * (ratingDatas.length + 3)}`].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: 'EFEFEF',
          },
        },
      };
    }

    worksheet_report['E10'].s = {
      alignment: {
        wrapText: true,
        vertical: 'top',
      },
    };

    worksheet_report['B15'].s = {
      alignment: {
        wrapText: true,
        vertical: 'top',
      },
    };

    console.log('worksheet_report', worksheet_report);

    XLSX.utils.book_append_sheet(workbook, worksheet_report, '리포트');

    XLSX.writeFile(workbook, '면접 결과_' + data.name + '.xlsx', {
      compression: true,
    });
  }

  async downInterviewRaw(interviewId: any) {
    const r = await this.ajax.post('e/downInterviewRawData', {
      interviewId,
    });

    const datas = r.data.datas;

    console.log(datas);

    const workbook = XLSX.utils.book_new();

    for (let data of datas) {
      data.checked = false;
      data.files = JSON.parse(data.files);
      data.interviewers = JSON.parse(data.interviewers);
      data.rating = JSON.parse(data.rating);
      data.interviewersName = '-';

      if (data.findeet_after !== '') {
        data.findeet_after = JSON.parse(data.findeet_after);
      }

      if (data.interviewers.length > 0) {
        data.interviewersName = this.getInterviewer(
          data.interviewers[0],
          'name',
        );
        if (data.interviewers.length > 1) {
          data.interviewersName += ` 외 ${data.interviewers.length - 1}명`;
        }
      }

      data.types = [];
      if (data.status <= 0) {
        if (
          data.status === 0 &&
          (!data.timeSet || data.interviewers.length === 0)
        ) {
          data.types.push(0);
        } else {
          data.types.push(1);
        }
      } else if (data.status === 1 || data.status === 2) {
        data.types.push(2);
      } else if (data.status === 3) {
        data.types.push(3);
      } else if (data.status === 4) {
        data.types.push(4);
      } else if (data.status === 5) {
        data.types.push(5);
      }

      data['면접 전형'] = this.getStep(data.step, 'name');
      data['직무 부서'] = this.getDept(data.dept, 'name');
      data['직무'] = this.getJob(data.job, 'name');
    }

    (() => {
      let data = datas[datas.length - 1];
      let interview: any = [
        //[data.name],
        [
          '고유 번호',
          '전형',
          '부서',
          '직무',
          '이름',
          '이메일',
          '연락처',
          '일자',
          '시간',
          '장소',
        ],
        [
          data.ino,
          data['면접 전형'],
          data['직무 부서'],
          data['직무'],
          data.name,
          data.email,
          data.phone,
          data.timeSet === 0 ? '-' : data.time1 + ' ' + data.time2,
          data.timeSet === 0 ? '-' : data.duration,
          data.place,
        ],
      ];

      const worksheet_interview = XLSX.utils.aoa_to_sheet(interview);
      XLSX.utils.book_append_sheet(workbook, worksheet_interview, '입사지원서');
    })();

    for (let data of datas) {
      let maxLevel = -1;
      let maxInterviewer = null;
      for (let interviewer of data.interviewers) {
        if (this.getInterviewer(interviewer, 'level') > maxLevel) {
          maxLevel = this.getInterviewer(interviewer, 'level');
          maxInterviewer = interviewer;
        }
      }

      if (maxInterviewer) {
        const transcriptArr = [];
        const r = await this.ajax.post('e/downInterviewRawData2', {
          i: data.id,
          iv: maxInterviewer,
        });
        const res = r.data.datas;

        console.log(res);

        for (let data of res) {
          transcriptArr.push([
            this.getInterviewer(data.speaker, 'name'),
            this.formatSeconds(data.time),
            data.text,
          ]);
        }

        const worksheet_transcript = XLSX.utils.aoa_to_sheet(transcriptArr);

        let sheetName = this.getStep(data.step, 'name') + '_면접 기록';

        XLSX.utils.book_append_sheet(workbook, worksheet_transcript, sheetName);
      }

      let ratingArr = [];

      let ratingHead = [''];

      for (let interviewer of data.interviewers) {
        ratingHead.push(this.getInterviewer(interviewer, 'name'));
        ratingHead.push('');
      }
      ratingHead.push('종합');
      ratingHead.push('');

      ratingArr.push(ratingHead);

      let ratingHead2 = ['역량'];
      let ratingHead3 = ['평가 결과'];

      for (let interviewer of data.interviewers) {
        ratingHead2.push('점수');
        ratingHead2.push('근거');
        ratingHead3.push('');
        ratingHead3.push('');
      }
      ratingHead2.push('역량 별 평균');
      ratingHead2.push('AI 면접 어시스트의 평가 근거 요약');

      ratingArr.push(ratingHead2);

      let ratingDatas: any = [];

      for (let i = 0; i < 3; i++) {
        for (let rating of data.rating[i]) {
          ratingDatas.push([ratingItems[i].items[rating].name]);
        }
      }

      let ratingResults: any = {};

      for (let interviewer of data.interviewers) {
        const r = await this.ajax.post('e/downInterviewData', {
          i: data.id,
          iv: interviewer,
        });
        const res = r.data.ratings;
        if (res.length > 0) {
          ratingResults[interviewer] = JSON.parse(res[0].data);
        }
      }

      let allScoreSum = 0;

      let ratingIdx = 0;
      for (let ratingData of ratingDatas) {
        let scoreSum = 0;
        let scoreCount = 0;
        for (let interviewer of data.interviewers) {
          if (typeof ratingResults[interviewer] === 'undefined') {
            ratingData.push('');
            ratingData.push('');
          } else {
            ratingData.push(ratingResults[interviewer][ratingIdx].score);
            ratingData.push(ratingResults[interviewer][ratingIdx].text);
            scoreSum += ratingResults[interviewer][ratingIdx].score;
            scoreCount += 1;
          }
        }
        if (scoreCount > 0) {
          scoreSum /= scoreCount;
        }

        allScoreSum += scoreSum;

        ratingData.push(scoreSum.toFixed(1));

        let find = false;
        if (typeof data.findeet_after.assessment !== 'undefined') {
          for (let key in data.findeet_after.assessment) {
            if (key.replace(/\s+/g, '') === ratingData[0].replace(/\s+/g, '')) {
              find = true;
              ratingData.push(data.findeet_after.assessment[key]);
              break;
            }
          }
        }

        if (!find) {
          ratingData.push('');
        }

        ratingIdx += 1;
      }
      if (ratingDatas.length > 0) {
        allScoreSum /= ratingDatas.length;
      }
      ratingHead3.push(allScoreSum.toFixed(1));
      //ratingHead3.push('전체 요약된 내용');
      ratingHead3.push('');

      ratingArr.push(ratingHead3);

      for (let ratingData of ratingDatas) {
        ratingArr.push(ratingData);
      }

      const worksheet_rating: any = XLSX.utils.aoa_to_sheet(ratingArr);
      let iIdx = 2;
      worksheet_rating['!merges'] = [];
      for (let interviewer of data.interviewers) {
        worksheet_rating['!merges'].push(
          XLSX.utils.decode_range(
            String.fromCharCode(64 + iIdx) +
              '1:' +
              String.fromCharCode(65 + iIdx) +
              '1',
          ),
        );
        iIdx += 2;
      }
      worksheet_rating['!merges'].push(
        XLSX.utils.decode_range(
          String.fromCharCode(64 + iIdx) +
            '1:' +
            String.fromCharCode(65 + iIdx) +
            '1',
        ),
      );

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet_rating,
        this.getStep(data.step, 'name') + '_평가내용',
      );
    }

    XLSX.writeFile(
      workbook,
      '면접 정보_' + datas[datas.length - 1].name + '.xlsx',
      {
        compression: true,
      },
    );
  }

  formatSeconds(seconds: number) {
    // 시간, 분, 초 계산
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;

    function padWithZero(number: number) {
      // 숫자가 10보다 작을 경우, 앞에 0을 추가하여 두 자리로 만듭니다.
      return (number < 10 ? '0' : '') + number;
    }
    // 시, 분, 초를 두 자리 수로 포맷팅
    var formattedHours = padWithZero(hours);
    var formattedMinutes = padWithZero(minutes);
    var formattedSeconds = padWithZero(remainingSeconds);

    // 시:분:초 형식으로 반환
    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
  }
}

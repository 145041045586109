<div class="flex h-[57px] items-center">
  <svg
    class="me-[8px] ms-[16px]"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2891 20.7578C10.7812 20.7578 10.3906 20.4531 10.2891 19.9609L9.89844 18.3438C9.65625 18.2656 9.42969 18.1719 9.21875 18.0703L7.80469 18.9375C7.38281 19.2031 6.89062 19.1562 6.53906 18.8047L5.53906 17.8125C5.1875 17.4609 5.14062 16.9453 5.42188 16.5234L6.28125 15.125C6.17969 14.9141 6.08594 14.6875 6.01562 14.4688L4.38281 14.0781C3.89062 13.9688 3.59375 13.5859 3.59375 13.0781V11.6797C3.59375 11.1797 3.89062 10.7969 4.38281 10.6875L6 10.2969C6.07812 10.0469 6.17969 9.82031 6.26562 9.63281L5.39844 8.20312C5.13281 7.77344 5.16406 7.28125 5.53125 6.92969L6.53906 5.9375C6.89844 5.60156 7.34375 5.54688 7.77344 5.78125L9.21094 6.66406C9.42188 6.5625 9.65625 6.46875 9.89844 6.38281L10.2891 4.75781C10.3906 4.26562 10.7812 3.96094 11.2891 3.96094H12.7109C13.2188 3.96094 13.6016 4.26562 13.7109 4.75781L14.0938 6.39844C14.3438 6.48438 14.5703 6.57031 14.7734 6.67188L16.2266 5.78125C16.6484 5.54688 17.0938 5.60938 17.4453 5.92969L18.4609 6.92969C18.8281 7.28125 18.8672 7.77344 18.5938 8.20312L17.7266 9.63281C17.8125 9.82031 17.9141 10.0469 17.9922 10.2969L19.6094 10.6875C20.1016 10.7969 20.3984 11.1797 20.3984 11.6797V13.0781C20.3984 13.5859 20.1016 13.9688 19.6094 14.0781L17.9766 14.4688C17.9062 14.6875 17.8125 14.9141 17.7188 15.125L18.5781 16.5234C18.8516 16.9453 18.8047 17.4609 18.4531 17.8125L17.4531 18.8047C17.1016 19.1562 16.6094 19.2031 16.1875 18.9375L14.7734 18.0703C14.5703 18.1719 14.3359 18.2656 14.0938 18.3438L13.7109 19.9609C13.6016 20.4531 13.2188 20.7578 12.7109 20.7578H11.2891ZM12 15.0781C13.4922 15.0781 14.7109 13.8516 14.7109 12.3516C14.7109 10.8672 13.4922 9.64844 12 9.64844C10.5078 9.64844 9.28125 10.8672 9.28125 12.3516C9.28125 13.8516 10.5078 15.0781 12 15.0781Z"
      fill="#0B0C0F"
    />
  </svg>
  <div class="select-none text-title-m text-neutral0">계정 설정</div>
</div>
<div class="flex w-full justify-center">
  <div class="flex w-[328px] flex-col">
    <ng-container
      *ngTemplateOutlet="tTitle; context: { title: '아바타 사진' }"
    ></ng-container>
    <div
      class="mb-[8px] flex h-[76px] items-center rounded-m bg-neutral90 p-[16px]"
      *ngFor="let file of files; let idx = index"
    >
      <div
        class="me-[8px] flex h-[36px] w-[36px] flex-shrink-0 items-center justify-center overflow-hidden rounded-m border border-neutral80 bg-neutral100"
      >
        <img crossorigin [src]="file.path" />
      </div>
      <div class="min-w-0 flex-auto">
        <div class="truncate text-title-s text-neutral0">{{ file.name }}</div>
        <div class="truncate text-body-s text-neutral60" *ngIf="file.size > 0">
          {{ util.humanFileSize(file.size) }}
        </div>
      </div>
      <div
        class="ms-[16px] flex h-[24px] w-[24px] flex-shrink-0 cursor-pointer items-center justify-center"
        (click)="files.splice(idx, 1)"
      >
        <svg
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.39844 18.5234C3.13281 18.5234 2.34375 17.7891 2.28906 16.5312L1.76562 5.35156H0.960938C0.53125 5.35156 0.1875 5.02344 0.1875 4.58594C0.1875 4.15625 0.53125 3.82812 0.960938 3.82812H4.35938V2.67969C4.35938 1.4375 5.14062 0.710938 6.5 0.710938H9.47656C10.8359 0.710938 11.625 1.4375 11.625 2.67969V3.82812H15.0312C15.4688 3.82812 15.8047 4.15625 15.8047 4.58594C15.8047 5.02344 15.4688 5.35156 15.0312 5.35156H14.2266L13.7031 16.5234C13.6484 17.7891 12.8516 18.5234 11.5938 18.5234H4.39844ZM5.95312 2.74219V3.82812H10.0312V2.74219C10.0312 2.36719 9.77344 2.13281 9.35938 2.13281H6.61719C6.21094 2.13281 5.95312 2.36719 5.95312 2.74219ZM4.59375 16.9844H11.3906C11.8281 16.9844 12.1172 16.6875 12.1328 16.2188L12.6328 5.35156H3.33594L3.85156 16.2188C3.875 16.6797 4.16406 16.9844 4.59375 16.9844ZM5.70312 15.7812C5.36719 15.7812 5.13281 15.5625 5.125 15.2266L4.89062 7.16406C4.88281 6.83594 5.10938 6.60938 5.46094 6.60938C5.79688 6.60938 6.03125 6.82812 6.03906 7.16406L6.27344 15.2188C6.28125 15.5469 6.05469 15.7812 5.70312 15.7812ZM8 15.7812C7.64844 15.7812 7.41406 15.5547 7.41406 15.2266V7.16406C7.41406 6.82812 7.64844 6.60938 8 6.60938C8.34375 6.60938 8.57812 6.82812 8.57812 7.16406V15.2266C8.57812 15.5547 8.34375 15.7812 8 15.7812ZM10.2891 15.7812C9.9375 15.7812 9.71094 15.5547 9.71875 15.2188L9.95312 7.16406C9.96094 6.82812 10.1953 6.60938 10.5312 6.60938C10.8828 6.60938 11.1094 6.83594 11.1016 7.16406L10.8672 15.2266C10.8594 15.5625 10.625 15.7812 10.2891 15.7812Z"
            fill="#0B0C0F"
          />
        </svg>
      </div>
    </div>
    <div
      class="mb-[4px] flex h-[138px] cursor-pointer flex-col items-center justify-center rounded-m border border-dashed border-neutral80 bg-neutral100"
      (dragover)="
        $event.preventDefault(); $event.stopPropagation(); dragged = true
      "
      (dragleave)="
        $event.preventDefault(); $event.stopPropagation(); dragged = false
      "
      (drop)="onDrop($event)"
      [class.border-blue]="dragged"
      [class.border-neutral80]="!dragged"
      [class.bg-lightblue]="dragged"
      [class.bg-neutral100]="!dragged"
      *ngIf="files.length < 1"
      (click)="fileInput.click()"
    >
      <input
        (change)="fileUpload($event)"
        class="hidden"
        type="file"
        #fileInput
        accept=".png, .jpg, .jpeg"
      />
      <div class="pointer-events-none mb-[8px] h-[36px] w-[36px] select-none">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7539 31.2305C9.24609 31.2305 7.94531 29.918 7.94531 27.3867V9.70312C7.94531 7.18359 9.24609 5.85938 11.7539 5.85938H17.1094V14.7188C17.1094 16.1836 17.8125 16.8984 19.2773 16.8984H28.043V27.3867C28.043 29.9062 26.7422 31.2305 24.2344 31.2305H11.7539ZM19.4883 15.1875C19.043 15.1875 18.8086 14.9648 18.8086 14.5195V5.97656C19.3477 6.04688 19.875 6.44531 20.4492 7.03125L26.8594 13.5352C27.457 14.1562 27.8438 14.6602 27.9141 15.1875H19.4883ZM17.9883 28.1953C18.5273 28.1953 18.9727 27.7852 18.9727 27.2578V23.3789L18.8789 21.6211L19.7695 22.5352L20.7305 23.5195C20.9062 23.6953 21.1523 23.8125 21.3867 23.8125C21.8906 23.8125 22.2773 23.4609 22.2773 22.9688C22.2773 22.6875 22.1719 22.4766 21.9609 22.2891L18.7383 19.3242C18.4805 19.0898 18.2578 18.9844 17.9883 18.9844C17.7188 18.9844 17.5078 19.0898 17.25 19.3242L14.0156 22.2891C13.8164 22.4766 13.7109 22.6875 13.7109 22.9688C13.7109 23.4609 14.0859 23.8125 14.6016 23.8125C14.8242 23.8125 15.082 23.6953 15.2578 23.5195L16.2188 22.5352L17.1094 21.6211L17.0156 23.3789V27.2578C17.0156 27.7852 17.4609 28.1953 17.9883 28.1953Z"
            [attr.fill]="dragged ? '#96C4FF' : '#D3D7DE'"
          />
        </svg>
      </div>
      <div
        class="pointer-events-none select-none text-center text-body-m text-neutral0"
      >
        PNG 또는 JPG 파일을 여기로<br />끌어다 놓거나 <u>파일을 선택</u>하세요.
      </div>
    </div>
    <ng-container
      *ngTemplateOutlet="tTitle; context: { title: '이름' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        tInput;
        context: {
          placeholder: '예. 홍길동',
          model: 'name',
          disabled: client.interviewer.level > 2 ? false : true
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="tTitle; context: { title: '이메일' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        tInput;
        context: {
          placeholder: '예. abcd@gmail.com',
          model: 'email',
          disabled: client.interviewer.level > 2 ? false : true
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="tTitle; context: { title: '휴대폰' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        tTel;
        context: {
          placeholder: '예. 01012345678',
          model: 'phone',
          disabled: client.interviewer.level > 2 ? false : true
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="tTitle; context: { title: '직무 부서' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        tSelectTextEdit;
        context: {
          value: '직무 부서',
          model: 'dept',
          disabled: lockDetail ? true : false
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="tTitle; context: { title: '직무' }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        tSelectTextEdit;
        context: {
          value: '직무',
          model: 'job',
          disabled: lockDetail ? true : false
        }
      "
    ></ng-container>
    <div class="flex h-[68px] flex-shrink-0 justify-end gap-[8px] pt-[12px]">
      <div
        class="h-[36px] cursor-pointer select-none rounded-m border-[2.4px] border-neutral0 px-[24px] py-[6px] text-label-l leading-[20px] text-neutral0"
        *ngIf="client.interviewer.firstLogin === 1"
        (click)="exit()"
      >
        취소하기
      </div>
      <div
        class="h-[36px] cursor-pointer select-none rounded-m bg-neutral0 px-[24px] py-[6px] text-label-l text-neutral100"
        (click)="submit()"
      >
        저장하기
      </div>
    </div>
    <div class="flex h-[32px] flex-shrink-0 justify-end gap-[8px]">
      <div
        class="cursor-pointer select-none text-label-m text-neutral50"
        (click)="leaveModalData.show = true"
      >
        회원 탈퇴하기
      </div>
    </div>
  </div>
</div>

<ng-template let-title="title" #tTitle>
  <div class="mb-[6px] mt-[12px] select-none text-label-s">
    {{ title }}
  </div>
</ng-template>

<ng-template
  let-placeholder="placeholder"
  let-model="model"
  #tInput
  let-disabled="disabled"
>
  <input
    class="mb-[4px] flex h-[36px] w-full rounded-m border border-neutral80 bg-neutral100 px-[12px] py-[6px] text-body-l"
    [class.text-neutral40]="disabled"
    [class.border-none]="disabled"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="inputs[model]"
    [disabled]="disabled"
    *ngIf="!disabled"
  />
  <div
    class="mb-[4px] flex h-[36px] w-full rounded-m bg-neutral100 px-[12px] py-[6px] text-body-l text-neutral40"
    *ngIf="disabled"
  >
    {{ inputs[model] }}
  </div>
</ng-template>

<ng-template
  let-placeholder="placeholder"
  let-model="model"
  #tTel
  let-disabled="disabled"
>
  <input
    class="mb-[4px] flex h-[36px] w-full rounded-m border border-neutral80 bg-neutral100 px-[12px] py-[6px] text-body-l"
    type="tel"
    [class.text-neutral40]="disabled"
    [class.border-none]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="inputs[model]"
    [disabled]="disabled"
    (keyup)="inputs[model] = inputs[model].replaceAll('-', '')"
    *ngIf="!disabled"
  />
  <div
    class="mb-[4px] flex h-[36px] w-full rounded-m bg-neutral100 px-[12px] py-[6px] text-body-l text-neutral40"
    *ngIf="disabled"
  >
    {{ inputs[model] }}
  </div>
</ng-template>

<ng-template
  let-value="value"
  let-model="model"
  let-disabled="disabled"
  #tSelectTextEdit
>
  <div class="flex" *ngIf="!disabled">
    <div
      class="mb-[4px] flex h-[36px] flex-auto items-center rounded-m border border-neutral80 bg-neutral100 px-[12px] py-[6px]"
      [class.border-none]="disabled"
    >
      <input
        type="text"
        class="w-full bg-neutral100 text-body-l"
        [(ngModel)]="inputs[model]"
        [attr.list]="model"
        [disabled]="disabled"
      />
      <datalist [id]="model">
        <option
          [attr.value]="data.name"
          *ngFor="let data of datas[model + 's']"
        ></option>
      </datalist>
    </div>
  </div>
  <div
    class="mb-[4px] flex h-[36px] w-full rounded-m bg-neutral100 px-[12px] py-[6px] text-body-l text-neutral40"
    *ngIf="disabled"
  >
    {{ inputs[model] }}
  </div>
</ng-template>

<ng-template let-value="value" let-model="model" #tSelectEdit>
  <div class="flex">
    <div
      class="mb-[4px] flex h-[36px] flex-auto items-center rounded-m border border-neutral80 bg-neutral100 px-[12px] py-[6px]"
    >
      <select class="flex-auto text-body-l" [(ngModel)]="inputs[model]">
        <option
          class="p-0"
          [ngValue]="data"
          *ngFor="let data of datas[model + 's']"
        >
          {{ data.name }}
        </option>
      </select>
    </div>
  </div>
</ng-template>

<app-modal
  *ngIf="leaveModalData.show"
  [data]="leaveModalData"
  (event)="onEvent($event)"
></app-modal>

import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';

@Component({
  selector: 'app-join-mail',
  templateUrl: './join-mail.component.html',
  styleUrl: './join-mail.component.scss',
})
export class JoinMailComponent {
  ajax = inject(AjaxService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  client = inject(ClientService);

  async ngOnInit() {
    const auth = this.route.snapshot.paramMap.get('auth');
    if (auth) {
      const r = await this.ajax.post('auth/getJoinData', {
        auth,
      });
      const res = r.data.datas;
      if (res.length > 0) {
        const id = res[0].id;
        const emailAuth = res[0].emailAuth;
        const level = res[0].level;
        if (emailAuth === 0) {
          if (level === 4) {
            // await this.ajax.query(
            //   `UPDATE interviewers SET emailAuth = 1 WHERE id = ?`,
            //   [id],
            // );
            this.client.setToast({
              data: '인증이 완료되어 서비스 이용이 가능합니다.',
            });
            this.router.navigate(['']);
          } else {
            this.router.navigate(['auth2', auth]);
          }
        } else if (emailAuth === 2) {
          this.router.navigate(['password-change', auth]);
        }
      } else {
        this.client.setToast({
          data: '유효하지 않은 인증 코드 입니다.',
        });
        this.router.navigate(['']);
      }
    } else {
      this.client.setToast({
        data: '유효하지 않은 인증 코드 입니다.',
      });
      this.router.navigate(['']);
    }
  }
}

import { Component, NgZone, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrl: './tutorial.component.scss',
})
export class TutorialComponent {
  currentPage = '';

  mode = 0;

  zoom = 1;

  route = inject(ActivatedRoute);
  zone = inject(NgZone);
  router = inject(Router);
  ajax = inject(AjaxService);
  client = inject(ClientService);

  // button : [width, height, top, left, event]

  pages: any = {
    '1-1': {
      buttons: [
        [146, 50, 294, 380, '1-2'],
        [
          106,
          48,
          632,
          1094,
          () => {
            this.end(1);
          },
        ],
      ],
      close: [40, 1098],
    },
    '1-2': {
      buttons: [
        [114, 28, 313, 520, '1-3-1'],
        [114, 28, 345, 520, '1-3-2'],
        [143, 48, 40, 40, '1-1'],
      ],
      close: [40, 1098],
    },
    '1-3-1': {
      buttons: [
        [60, 60, 620, 1053, '1-4-1'],
        [143, 48, 40, 40, '1-2'],
      ],
      close: [632, 40],
    },
    '1-4-1': {
      buttons: [
        [60, 60, 620, 1053, '1-5-1'],
        [143, 48, 40, 40, '1-3-1'],
      ],
      close: [632, 40],
    },
    '1-5-1': {
      buttons: [
        [60, 60, 620, 1053, '1-6-1'],
        [143, 48, 40, 40, '1-4-1'],
      ],
      close: [632, 40],
    },
    '1-6-1': {
      buttons: [
        [60, 60, 620, 1053, '1-7-1'],
        [143, 48, 40, 40, '1-5-1'],
      ],
      close: [632, 40],
    },
    '1-7-1': {
      buttons: [
        [60, 60, 620, 1053, '1-8-1'],
        [143, 48, 40, 40, '1-6-1'],
      ],
      close: [632, 40],
    },
    '1-8-1': {
      buttons: [
        [97, 36, 660, 1119, '1-9-1'],
        [143, 48, 40, 40, '1-7-1'],
      ],
      close: [632, 40],
    },
    '1-9-1': {
      buttons: [
        [60, 60, 620, 1053, '1-10-1'],
        [143, 48, 40, 40, '1-8-1'],
      ],
      close: [632, 40],
    },
    '1-10-1': {
      buttons: [
        [97, 36, 660, 1119, '1-11-1'],
        [143, 48, 40, 40, '1-9-1'],
      ],
      close: [632, 40],
    },
    '1-11-1': {
      buttons: [
        [97, 36, 660, 1119, '1-12-1'],
        [143, 48, 40, 40, '1-10-1'],
      ],
      close: [632, 40],
    },
    '1-12-1': {
      buttons: [
        [
          113,
          50,
          653,
          1111,
          () => {
            this.end(1);
          },
        ],
        [143, 48, 40, 40, '1-11-1'],
      ],
      close: [632, 40],
    },
    '1-3-2': {
      buttons: [
        [93, 43, 156, 1117, '1-4-2'],
        [143, 48, 40, 40, '1-2'],
      ],
      close: [632, 40],
    },
    '1-4-2': {
      buttons: [
        [288, 138, 237, 928, '1-5-2'],
        [143, 48, 40, 40, '1-3-2'],
      ],
      close: [632, 40],
    },
    '1-5-2': {
      buttons: [
        [
          97,
          36,
          660,
          1119,
          () => {
            this.end(1);
          },
        ],
        [143, 48, 40, 40, '1-4-2'],
      ],
      close: [632, 40],
    },
    '2-1': {
      buttons: [
        [219, 184, 227, 333, '2-2'],
        [
          143,
          48,
          40,
          40,
          () => {
            this.prev(2);
          },
        ],
        [
          106,
          48,
          40,
          1094,
          () => {
            this.end(2);
          },
        ],
      ],
    },
    '2-2': {
      buttons: [
        [320, 449, 247, 92, '2-3'],
        [143, 48, 40, 40, '2-1'],
      ],
      close: [40, 1098],
    },
    '2-3': {
      buttons: [
        [111, 44, 528, 818, '2-4'],
        [143, 48, 40, 40, '2-2'],
      ],
      close: [632, 40],
    },
    '2-4': {
      buttons: [
        [34, 36, 592, 815, '2-5'],
        [143, 48, 40, 40, '2-3'],
      ],
      close: [632, 40],
    },
    '2-5': {
      buttons: [
        [63, 36, 195, 235, '2-6'],
        [143, 48, 40, 40, '2-4'],
      ],
      close: [632, 40],
    },
    '2-6': {
      buttons: [
        [97, 36, 660, 1119, '2-7'],
        [143, 48, 40, 40, '2-5'],
      ],
      close: [632, 40],
    },
    '2-7': {
      buttons: [
        [49, 36, 195, 662, '2-8'],
        [143, 48, 40, 40, '2-6'],
      ],
      close: [40, 1098],
    },
    '2-8': {
      buttons: [
        [130, 36, 491, 533, '2-9'],
        [143, 48, 40, 40, '2-7'],
      ],
      close: [40, 1098],
    },
    '2-9': {
      buttons: [
        [216, 36, 66, 124, '2-10'],
        [143, 48, 632, 40, '2-8'],
      ],
      close: [632, 1098],
    },
    '2-10': {
      buttons: [
        [103, 36, 66, 1113, '2-11'],
        [143, 48, 632, 40, '2-9'],
      ],
      close: [632, 1098],
    },
    '2-11': {
      buttons: [
        [
          130,
          36,
          66,
          634,
          () => {
            this.end(2);
          },
        ],
        [143, 48, 632, 40, '2-10'],
      ],
      close: [632, 1098],
    },
    '3-1': {
      buttons: [
        [46, 46, 651, 1067, '3-2'],
        [
          143,
          48,
          40,
          40,
          () => {
            this.prev(3);
          },
        ],
        [
          106,
          48,
          40,
          1094,
          () => {
            this.end(3);
          },
        ],
      ],
    },
    '3-2': {
      buttons: [
        [28, 20, 342, 177, '3-3'],
        [143, 48, 40, 40, '3-1'],
      ],
      close: [40, 1098],
    },
    '3-3': {
      buttons: [
        [46, 46, 651, 1067, '3-4'],
        [143, 48, 40, 40, '3-2'],
      ],
      close: [40, 1098],
    },
    '3-4': {
      buttons: [
        [105, 28, 481, 330, '3-5'],
        [143, 48, 40, 40, '3-3'],
      ],
      close: [40, 1098],
    },
    '3-5': {
      buttons: [
        [373, 218, 317, 85, '3-6'],
        [143, 48, 40, 40, '3-4'],
      ],
      close: [40, 1098],
    },
    '3-6': {
      buttons: [
        [105, 28, 545, 278, '3-7'],
        [143, 48, 40, 40, '3-5'],
      ],
      close: [40, 1098],
    },
    '3-7': {
      buttons: [
        [
          413,
          181,
          319,
          795,
          () => {
            this.end(3);
          },
        ],
        [143, 48, 40, 40, '3-6'],
      ],
      close: [100, 40],
    },
    // '3-8': {
    //   buttons: [
    //     [
    //       28,
    //       28,
    //       656,
    //       1172,
    //       () => {
    //         this.end(3);
    //       },
    //     ],
    //     [143, 48, 40, 40, '3-7'],
    //   ],
    // },
    '4-1': {
      buttons: [
        [130, 36, 66, 634, '4-2'],
        [
          143,
          48,
          40,
          40,
          () => {
            this.prev(4);
          },
        ],
        [
          106,
          48,
          40,
          1094,
          () => {
            this.end(4);
          },
        ],
      ],
    },
    '4-2': {
      buttons: [
        [49, 36, 255, 539, '4-3'],
        [143, 48, 40, 40, '4-1'],
      ],
      close: [632, 40],
    },
    '4-3': {
      buttons: [
        [145, 36, 300, 627, '4-4'],
        [143, 48, 40, 40, '4-2'],
      ],
      close: [40, 1098],
    },
    '4-4': {
      buttons: [
        [73, 38, 358, 229, '4-5'],
        [143, 48, 40, 40, '4-3'],
      ],
      close: [40, 1098],
    },
    '4-5': {
      buttons: [
        [157, 36, 66, 607, '4-6'],
        [143, 48, 40, 1057, '4-4'],
      ],
    },
    '4-6': {
      buttons: [
        [60, 74, 330, 8, '4-7'],
        [143, 48, 632, 40, '4-5'],
      ],
      close: [40, 1098],
    },
    '4-7': {
      buttons: [
        [
          143,
          48,
          40,
          1057,
          () => {
            this.end(4);
          },
        ],
        [143, 48, 40, 40, '4-6'],
      ],
    },
  };

  e: any;

  constructor() {
    console.log('tutorial', this);
  }

  async ngOnInit() {
    const mode = this.route.snapshot.paramMap.get('mode');
    this.currentPage = '1-1';
    if (mode) {
      this.mode = parseInt(mode);
      this.currentPage = mode + '-1';
      for (const key in this.pages) {
        if (key.startsWith(mode)) {
          console.log(key);
          const img = new Image();
          img.src = 'assets/imgs/tutorial/' + key + '.png';
        }
      }
    } else {
      for (const key in this.pages) {
        console.log(key);
        const img = new Image();
        img.src = 'assets/imgs/tutorial/' + key + '.png';
      }
    }

    this.updateZoom();
    this.e = this.updateZoom.bind(this);
    window.addEventListener('resize', this.e);
  }

  updateZoom() {
    console.log('updateZoom');
    let w = window.innerWidth;
    let h = window.innerHeight;
    let zoom1 = w / 1240;
    let zoom2 = h / 720;
    this.zone.run(() => {
      this.zoom = Math.min(zoom1, zoom2);
    });
  }

  async movePage(target: any) {
    if (typeof target === 'string') {
      await this.preloadImage(target);
      this.currentPage = target;
    } else if (typeof target === 'function') {
      target();
    }
  }

  preloadImage(page: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const img = new Image();
      img.src = `assets/imgs/tutorial/${page}.png`;
      img.onload = () => resolve();
    });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.e);
  }

  prev(target: number) {
    if (this.mode === target) {
      this.home();
    } else {
      this.currentPage = target - 1 + '-1';
    }
  }

  async end(target: number) {
    if (this.mode === 0) {
      if (target < 4) {
        this.currentPage = target + 1 + '-1';
      } else {
        await this.ajax.post('e/endTutorial', {});
        this.client.interviewer.tutorial = 1;
        this.home();
      }
    } else {
      this.home();
    }
  }

  async close() {
    await this.ajax.post('e/endTutorial', {});
    this.client.interviewer.tutorial = 1;
    this.home();
  }

  home() {
    this.router.navigate(['manage', 'setting']);
  }
}

import { Component, EventEmitter, Output, inject } from '@angular/core';
import { ClientService } from '../../core/services/client.service';
import { AjaxService } from '../../core/services/ajax.service';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  @Output() event = new EventEmitter();

  topMenus = ['전체', '면접', '워크스페이스'];
  currentMenu = '전체';

  client = inject(ClientService);
  ajax = inject(AjaxService);
  router = inject(Router);
  socket = inject(Socket);

  remainCount = 0;

  origDatas: any = [];
  datas: any = [];

  dayjs = dayjs;

  cb = {
    updateAlerts: async (data: any) => {
      await this.updateAlerts();
    },
  };

  async ngOnInit() {
    console.log('alert', this);
    await this.updateAlerts();
    this.socket.on('updateAlerts', this.cb.updateAlerts);
  }

  async ngOnDestroy() {
    this.socket.removeListener('updateAlerts', this.cb.updateAlerts);
  }

  async updateAlerts() {
    const alertsRes = await this.ajax.post('alert/getAlerts', {
      token: this.client.token,
    });

    const alerts = alertsRes.data.alerts;

    console.log(alerts);
    for (let data of alerts) {
      data.data = JSON.parse(data.data);
    }

    this.origDatas = alerts;

    await this.updateCount();
    this.processData();
  }

  async readAll() {
    await this.ajax.post('alert/readAll', {
      token: this.client.token,
    });
    for (let data of this.origDatas) {
      data.isRead = 1;
    }
    this.remainCount = 0;
    this.client.events.alert.emit({
      id: 'alertCount',
      data: 0,
    });
  }

  async read(data: any) {
    if (data.isRead === 0) {
      await this.ajax.post('alert/read', {
        token: this.client.token,
        id: data.id,
      });
      data.isRead = 1;
      await this.updateCount();
    }
  }

  async updateCount() {
    let remainCount = 0;
    for (let data of this.origDatas) {
      console.log(data.isRead);
      if (data.isRead === 0) {
        remainCount += 1;
      }
    }
    this.remainCount = remainCount;
    console.log('updateCount', remainCount);

    this.client.events.alert.emit({
      id: 'alertCount',
      data: remainCount,
    });
  }

  processData() {
    if (this.currentMenu === '전체') {
      this.datas = this.origDatas;
    } else if (this.currentMenu === '면접') {
      this.datas = this.origDatas.filter((d: any) => {
        return d.type.startsWith('interview-');
      });
    } else if (this.currentMenu === '워크스페이스') {
      this.datas = this.origDatas.filter((d: any) => {
        return d.type.startsWith('interviewer-');
      });
    }
  }

  async moveInterview(data: any) {
    const res = await this.ajax.post('i/isValidInterviewId', {
      id: data.interview,
    });
    console.log(res);
    if (res.data.count > 0) {
      this.router.navigate(['interview', data.interview]);
      this.event.emit({ id: 'alert', data: 'close' });
    } else {
      this.client.setToast({
        bg: 'red',
        data: '삭제된 면접입니다',
      });
    }
  }

  moveManage() {
    this.router.navigate(['manage']);
    this.event.emit({ id: 'alert', data: 'close' });
  }
}

import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class AjaxService {
  API_URL = environment.apiUrl;
  API_FILE_URL = environment.apiFileUrl;

  injector = inject(Injector);

  constructor(public http: HttpClient) {
    if (location.hostname !== 'findeet.io') {
      //this.API_URL = 'http://' + location.hostname + ':9591/';
      //this.API_URL2 = 'http://' + location.hostname + ':9591/';
    }
  }

  get(url: string): any {
    return this.http.get(this.API_URL + url).toPromise();
  }

  async post(url: string, data: any): Promise<any> {
    if (typeof data['token'] === 'undefined') {
      const client = this.injector.get(ClientService);
      data['token'] = client.token;
    }
    return await lastValueFrom(
      this.http.post(this.API_URL + url, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      }),
    );
  }

  getFile(url: string): any {
    //return this.FILE_URL + url;
    return url;
  }

  uploadFile(file: File, options: any = {}) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);

      const uploadReq = new HttpRequest(
        'POST',
        this.API_FILE_URL + 'uploadFile',
        formData,
        {
          reportProgress: true,
        },
      );

      this.http.request(uploadReq).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              if (typeof options.onProgress === 'function') {
                const progress = Math.round((100 * event.loaded) / event.total);
                options.onProgress(progress);
              }
            }
          } else if (event.type === HttpEventType.Response) {
            resolve(event.body);
          }
        },
        error: (e) => {
          reject(e);
        },
        complete: () => {
          resolve({});
        },
      });
    });
  }

  async query(query: string, data: any = []) {
    const res = await this.post('query', {
      query,
      data,
    });
    return res.data;
  }

  async socket(room: string, name: string, data: any = {}) {
    const res = await this.post('socket', {
      room,
      name,
      data,
    });
    return res.data;
  }
}

import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  ajax = inject(AjaxService);
  client = inject(ClientService);
  router = inject(Router);
  sanitizer = inject(DomSanitizer);

  username = '';
  password = '';

  emailError = false;
  passwordError = false;

  step = 0;
  changePasswordSend = false;

  async ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.router.navigate(['interview']);
    }
  }

  ngOnDestroy() {}

  async checkValidEmail() {
    const r = await this.ajax.post('auth/checkEmail', {
      email: this.username,
    });
    console.log(r);
    if (r.data.error !== 0) {
      this.client.setToast({
        bg: 'red',
        data: '이메일이 유효하지 않습니다.',
      });
      this.emailError = true;
      return false;
    }
    return true;
  }

  async emailEnter() {
    if (this.step === 0) {
      this.step = 1;
      if (await this.checkValidEmail()) {
        setTimeout(() => {
          this.passwordInput.nativeElement.focus();
        }, 0);
      }
    }
  }

  async submit() {
    if (this.step === 0) {
      this.step = 1;
      if (await this.checkValidEmail()) {
        setTimeout(() => {
          this.passwordInput.nativeElement.focus();
        }, 0);
      }
    } else if (this.step === 1) {
      this.emailError = false;

      if (!(await this.checkValidEmail())) {
        return;
      }
      const loginRes = await this.ajax.post('login', {
        username: this.username,
        password: this.password,
      });

      console.log(loginRes);
      if (loginRes.error === 0) {
        const res = loginRes.data;
        if (res.emailAuth === 0) {
          this.router.navigate(['email', this.username]);
        } else {
          this.client.token = res.token;
          localStorage.setItem('token', res.token);
          this.router.navigate(['interview']);
        }
      } else {
        this.client.setToast({
          bg: 'red',
          data: '잘못된 비밀번호입니다.',
        });
        this.passwordError = true;
      }
    }
  }

  async changePassword() {
    if (!this.changePasswordSend) {
      this.changePasswordSend = true;

      const r = await this.ajax.post('auth/changePassword', {
        email: this.username,
      });

      if (r.data.error === 0) {
        this.router.navigate(['email', this.username]);
      } else {
        this.client.setToast({
          bg: 'red',
          data: '이메일이 존재하지 않습니다.',
        });
        this.changePasswordSend = false;
      }
    }
  }
}

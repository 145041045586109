export const ratingItems = [
  {
    tab: '개인역량',
    groups: [
      { name: '종합사고', start: 0, count: 1 },
      { name: '업무조직화능력', start: 1, count: 6 },
      { name: '업무관리능력', start: 7, count: 2 },
      { name: '문제해결능력', start: 9, count: 5 },
      { name: '관계관리능력', start: 14, count: 4 },
    ],
    items: [
      {
        name: '논리적사고',
        desc: '주어진 정보들의 논리적 연계성을 명확히 파악하고, 명확한 논리적 근거를 바탕으로 합리적인 의견을 제시한다',
        detail: [
          '다양한 정보나 자료 간 연계성을 파악하여 논리적으로 설명한다',
          '논리적 모순에 빠지지 않고 일관된 맥락으로 자신의 의견을 제시한다',
          '타당한 논리적 근거를 바탕으로 어떤 현상에 대한 합리적인 의견을 제시한다',
        ],
      },
      {
        name: '계획력',
        desc: '어떤 일을 하기 위해 필요한 자원을 파악/확보하고, 구체적인 일정 계획을 수립하며, 발생가능한 장애요소를 파악하여 대응 방안을 마련한다',
        detail: [
          '활용 가능한 인적/물적 자원을 탐색하고, 이를 확보할 수 있는 방안을 수립한다',
          '목표달성을 위한 세부과제를 정의하고, 우선순위에 따라 구체적인 계획(일정, 방법, 세부절차 등)을 수립한다',
          '발생가능한 장애요소를 미리 파악하고, 이에 대한 대응 전략을 마련한다',
        ],
      },
      {
        name: '기획력',
        desc: '이해관계자의 특징과 니즈를 파악하여, 목표를 달성할 수 있는 합리적 대안을 구상하고, 대안의 타당성을 확보하기 위한 방안을 도출한다',
        detail: [
          '이해관계자(자신, 소속 집단, 고객 등)의 특징과 니즈를 분명하게 파악한다',
          '이해관계자(자신, 소속 집단, 고객 등)의 목표를 달성할 수 있는 합리적인 대안과 구체적인 방법을 구상한다',
          '대안의 타당성을 분석하고 확보할 수 있는 방안(일정, 방법, 세부절차 등)을 수립한다',
        ],
      },
      {
        name: '목표설정및관리',
        desc: '명확한 목표와 성과기준을 설정하고, 구체적인 목표 달성 방안을 수립하며, 그 과정을 체계적으로 점검/관리한다',
        detail: [
          '도전적이고 실현가능한 목표를 설정하고, 목표 달성 여부를 평가할 수 있는 방안을 마련한다',
          '설정한 목표를 달성하기 위한 세부과제를 설명하고, 구체적인 계획을 수립한다',
          '목표 달성 과정에 대한 정기적 점검 계획을 수립하고 지속적으로 관리한다',
        ],
      },
      {
        name: '비즈니스마인드',
        desc: '정부 정책, 비즈니스 관행, 시장 동향, 기술 등이 조직과 사업에 미치는 영향을 파악하여 업무에 반영한다',
        detail: [
          '국내외 동종계열 경쟁사 및 시장상황에 대한 정보를 지속적으로 수집한다',
          '조직의 사업분야와 관련된 최신의 정치, 사회, 경제적 상황을 파악하고 있다',
          '조직을 둘러싼 주변의 환경 변화가 미래 조직에 미치는 영향을 예측한다',
        ],
      },
      {
        name: '조직이해',
        desc: '조직의 공식적인 구조와 비공식적인 문화와 가치관을 파악하며, 조직 내에서 자신의 역할이 무엇인지 명확히 이해한다',
        detail: [
          '조직의 공식적인 구조, 위계, 규정, 운영 절차, 수행 사업 등에 대해 이해한다',
          '조직의 비전, 목표, 핵심가치, 문화, 가치관 등을 이해하고 받아들인다',
          '자신의 전문성이나 맡은 역할(업무)이 조직과 사업에 어떻게 연계되어 있는지 파악한다',
        ],
      },
      {
        name: '프로세스지향성',
        desc: '업무 처리 프로세스를 명확히 이해하고 이를 준수하며, 보다 효율적/효과적인 방식으로 개선한다',
        detail: [
          '각 프로세스 별 의미와 그것이 결과에 미치는 영향을 파악한다',
          '일을 처리함에 있어 정해진 프로세스를 준수한다',
          '보다 효율적/효과적으로 업무를 처리할 수 있는 방식으로 업무 프로세스를 개선한다',
        ],
      },
      {
        name: '세밀한일처리',
        desc: '일을 하면서 오류나 실수가 발생하지 않도록 사전과 중간에 신중하게 점검하고, 세부적인 사항까지 꼼꼼하게 검토한다',
        detail: [
          '오류나 실수가 발생할 소지를 미리 파악하여 주의를 기울인다',
          '일을 수행하면서 오류가 발생하지 않도록 일의 진행 과정을 철저하게 점검하고 기록한다',
          '일의 세부적인 사항까지 꼼꼼하고 치밀하게 살펴본다',
        ],
      },
      {
        name: '정보수집및활용',
        desc: '주어진 상황에서 필요한 정보를 재빨리 수집하고, 체계적으로 정리하며, 이를 적절히 활용하여 문제를 해결한다',
        detail: [
          '다양한 경로와 방법을 활용하여 자신의 전문분야 또는 관심분야와 관련된 정보를 수집한다',
          '수집한 정보를 활용하기 쉽도록 체계적으로 분류/정리한다',
          '수집한 정보를 적절히 활용하여 긍정적 결과를 이끌어낸다',
        ],
      },
      {
        name: '결단력',
        desc: '즉각적인 의사결정이 필요한 상황에서, 객관적 근거와 예상되는 결과를 고려하여 과감한 결단을 내린다',
        detail: [
          '의사결정이 가지는 위험성을 최소화하기 위해 보유한 객관적 자료를 적극적으로 활용한다',
          '타인에게 미칠 영향 또는 장/단기적 결과를 고려하여 의사결정을 내린다',
          '주어진 상황이 불확실할지라도 다양한 대안을 고려해 신속하게 의사결정을 내린다',
        ],
      },
      {
        name: '변화대응및적응',
        desc: '상황이나 환경의 변화를 이해하고 능동적으로 받아들이며, 이에 대해 타인에게 전파한다',
        detail: [
          '상황이나 환경의 변화를 인지하고, 변화의 필요성을 인식한다',
          '상황이나 환경의 변화에 따라 새로운 지식이나 기술을 능동적으로 받아들인다',
          '자신이 받아들인 변화에 대해 타인에게 공유하고 전파한다',
        ],
      },
      {
        name: '분석력',
        desc: '상황, 문제, 현상 등을 하위요소로 분해하여 시사점을 도출하고, 이러한 결과를 활용하여 문제를 해결한다',
        detail: [
          '특정 문제에 대해 다양한 요인들을 규명함으로써 문제나 상황의 본질과 원인을 규명해 낸다',
          '서로의 연관관계가 불명확한 상황에서 일정한 패턴이나 연결고리를 발견해낸다',
          '분석한 결과를 적절히 결합하여 문제를 해결한다',
        ],
      },
      {
        name: '전문성',
        desc: '자신의 전문성이 필요한 상황에서 적절히 문제를 해결하며, 지속적으로 전문성을 향상시킨다',
        detail: [
          '어떤 상황에서 자신의 전문성이 가장 요구되고, 잘 발휘될 수 있는지 이해한다',
          '자신의 전문성을 활용하여 문제를 해결한다',
          '자신의 전문 분야에 대해 최신 트렌드를 파악하고, 지속적으로 변화에 대해 준비한다',
        ],
      },
      {
        name: '창의력',
        desc: '기존과 다른 방식으로 문제에 접근하여, 독창적인 아이디어를 풍부하게 구상한다',
        detail: [
          '기존과 다른 새로운 방식으로 문제에 접근한다',
          '다른 사람들이 생각하지 못한 현실적이며 유용한 아이디어를 구상한다',
          '문제 해결을 위한 아이디어를 풍부하게 구상한다',
        ],
      },
      {
        name: '설득력',
        desc: '설득 전략 · 구체적 근거 · 의사전달 기술 등을 활용하여, 상대방이 자신의 생각에 따르도록 한다',
        detail: [
          '상대방이 관심사와 니즈를 미리 파악하여, 적절한 설득 전략을 구상/활용한다',
          '구체적인 자료나 사례, 시범, 연구자료 등을 활용하여 보다 명확하게 전달한다',
          '의사전달 스킬(시선처리, 음성, 제스쳐 등)을 적절히 활용하여 자신의 입장을 효과적으로 전달한다',
        ],
      },
      {
        name: '의사소통',
        desc: '상대방의 생각과 의도를 명확히 이해하고, 자신의 생각과 의도를 명확하고 간결하게 전달한다',
        detail: [
          '상대방의 의견을 경청하여 핵심 내용과 의도를 명확하게 파악한다',
          '구두 또는 문서를 통해 자신의 의사를 분명하게 전달한다',
          '적절한 목소리와 어휘를 사용하여 자신의 의사를 표현한다',
        ],
      },
      {
        name: '인적네트워킹',
        desc: '다른 사람들과 긴밀한 관계를 구축하고 정보를 공유하며, 유사시 도움을 주고받을 수 있도록 네트워크를 유지한다',
        detail: [
          '동호회 및 모임에 참여하여 다양한 사람들과 폭넓은 인간관계를 구축한다',
          '지속적으로 우호적 관계를 유지하기 위해 노력한다',
          '정보를 공유하거나 도움을 주는 등 자신의 인간관계를 적절히 활용한다',
        ],
      },
      {
        name: '협상력',
        desc: '자신과 상대방의 입장 차이를 인식하고, 전략적으로 상호 이익이 되는 합의안을 도출한다',
        detail: [
          '자신과 상대방이 처한 상황과 서로의 입장차이를 명확히 인식한다',
          '협상의 쟁점을 예상하고 적절한 양보와 타협안을 미리 생각한다',
          '자신과 상대방 모두에게 이익이 되는 합의안을 도출해낸다',
        ],
      },
    ],
  },
  {
    tab: '조직역량(컬처)',
    groups: [
      { name: '개인 내적인 태도', start: 0, count: 10 },
      { name: '타인에 대한 태도', start: 10, count: 7 },
      { name: '일에 대한 태도', start: 17, count: 13 },
    ],
    items: [
      {
        name: '개방성',
        desc: '새로운 방식과 비판적인 의견, 그리고 사람들 사이의 다양성 등을 인정하고 받아들인다',
        detail: [
          '기존 관행이나 틀에 얽매이지 않고 새로운 방식을 적극적으로 받아들인다',
          '반대 의견이나 비판적인 의견이라도 끝까지 듣고 긍정적으로 받아들인다',
          '사람들 사이의 다양성을 인정하고 받아들인다',
        ],
      },
      {
        name: '긍정적사고',
        desc: '미래를 희망적으로 바라보고, 주변 사람들도 긍정적인 마음을 갖도록 도와주며, 실패와 비판을 긍정적으로 받아들인다',
        detail: [
          '어떤 상황에서도 할 수 있다는 생각을 갖는다',
          '항상 긍정적인 생각과 밝은 모습으로 주변 사람들에게 긍정적 영향을 미친다',
          '실패에 부딪혀도 쉽게 낙담하지 않고 긍정적으로 받아들인다',
        ],
      },
      {
        name: '비판적사고',
        desc: '기존의 방식에 대해 문제의식을 갖고, 보다 합리적인 판단을 위한 근거를 탐색하며, 자신의 생각에 대해서도 비판적으로 검토한다',
        detail: [
          '기존의 방식이나 방법에 대해 문제의식을 갖고 다른 관점에서 사고한다',
          '근거가 부족한 부분을 찾아내고, 합리적 판단을 위해 더 필요한 증거가 무엇인지 탐색한다',
          '자신의 문제해결 방식에 존재할 수 있는 편향성이나 오류를 검토한다',
        ],
      },
      {
        name: '솔선수범',
        desc: '자신의 희생을 감수하더라도, 자발적으로 솔선수범하는 태도를 보여, 다른 사람들의 행동에 긍정적 영향을 미친다',
        detail: [
          '누군가의 지시가 없더라도 자발적으로 모범적인 언행과 태도를 보인다',
          '자신에게 이익이 없고, 경우에 따라 자신의 희생을 감수하더라도 모범적인 언행과 태도를 보인다',
          '자신의 모범적인 언행과 태도를 통해, 다른 사람들도 이에 따라오도록 한다',
        ],
      },
      {
        name: '스트레스내성',
        desc: '스트레스 상황을 잘 견뎌내며, 스트레스를 받더라도 이를 절적히 관리하고 대처한다',
        detail: [
          '어려움이 있더라도, 육체적/정신적 스트레스를 크게 받지 않는다',
          '스트레스를 효과적으로 관리하는 자신만의 노하우가 있다',
          '스트레스 상황에서도 침착하게 현황과 원인을 파악하고, 긍정적으로 해결할 수 있는 방안을 구상한다',
        ],
      },
      {
        name: '유연성',
        desc: '상황이나 환경의 변화에 맞추어 자신의 인식과 태도를 변경하고, 기존의 전략과 규칙 등을 유연하게 적용하여 일을 처리한다',
        detail: [
          '상황이나 환경의 변화에 민감하게 반응하며, 지속적으로 변화에 대한 정보를 탐색한다',
          '새로운 정보나 반증이 있는 경우, 타당성이 인정되면 자신의 인식과 태도를 기꺼이 변경한다',
          '어떤 일을 처리하기 위해, 정해진 규칙이나 통상적인 절차를 상황에 맞게 수정하여 적용한다',
        ],
      },
      {
        name: '윤리의식',
        desc: '도덕적/윤리적 가치를 중요시하고, 비윤리적 행동에 민감하게 반응하며, 상황에 적합한 도덕적 기준을 적용한다',
        detail: [
          '도덕적/윤리적 가치를 중요시하며, 자발적으로 도덕적/윤리적 기준에 따라 결정하고 행동한다',
          '비윤리적인 행동에 문제의식을 갖고, 향후 발생할 수 있는 윤리적 문제를 파악한다',
          '공과 사를 명확히 구분하고, 이해관계자와 자신이 처한 상황을 고려하여 가장 바람직한 기준을 적용한다',
        ],
      },
      {
        name: '자기개발',
        desc: '자신의 상황과 강·약점을 인식하고, 타인의 평가를 적극적으로 수용하며, 자신의 부족한 점을 개선하기 위해 지속적으로 노력한다',
        detail: [
          '자신이 처한 상황이나, 자신의 강약점에 대해 객관적으로 인식하고 있다',
          '타인의 평가나 충고가 자신의 생각과 다르더라도, 이를 진지하게 받아들인다',
          '자신의 부족한 점을 개선하기 위해 다양한 노력을 지속적으로 기울인다',
        ],
      },
      {
        name: '자기조절',
        desc: '스트레스 상황에서 자신의 감정을 잘 조절하고, 부적절한 행동을 삼가며, 침착함을 잃지 않고 자신의 목표에 집중한다',
        detail: [
          '스트레스를 받는 상황에서도 자신의 감정을 통제하여 주변 사람들에게 드러내지 않는다',
          '부절적한 행동을 하고자 하는 충동을 자제한다',
          '육체적, 정신적 또는 상황적 어려움이 있더라도, 침착함을 잃지 않고 자신의 목표에 집중한다',
        ],
      },
      {
        name: '자신감',
        desc: '자신을 가치있는 사람이라 여기며, 자신의 능력에 확신을 갖고, 무엇이든 해낼 수 있다는 믿음을 가진다',
        detail: [
          '자신을 충분히 가치있는 사람이라 여기며, 다른 사람들도 자신을 그렇게 평가할 것이라 생각한다',
          '어떤 일에 대한 자신의 능력이나 판단력에 확신을 갖는다',
          '무슨 일이더라도 실패를 두려워하기보다 해낼 수 있다는 긍정적인 자세로 임한다',
        ],
      },
      {
        name: '고객지향',
        desc: '고객이 누구인지 인식하고, 그들의 요구사항을 파악하며, 요구사항에 대해 적극적으로 대처하여 고객을 만족시킨다',
        detail: [
          '자신의 일과 관련된 조직 내/외부의 고객에 대해 명확히 인식한다',
          '고객의 관점에서, 고객의 요구사항을 파악하기 위하여 다양한 노력을 기울인다',
          '고객의 요구사항에 대해 적극적으로 대응함으로써, 고객이 원하는 바를 충족시킨다',
        ],
      },
      {
        name: '공동체의식',
        desc: '공동체의 가치와 자신의 역할을 명확히 인식하며, 공동체의 화합과 발전을 위해 다양한 노력을 기울인다',
        detail: [
          '자신이 공동체 내 구성원들과 하나라는 인식을 바탕으로, 공동체의 가치를 중요하게 여긴다',
          '공동체 구성원으로서 자신의 역할과 의무를 명확히 인식한다',
          '자신이 속한 공동체의 화합과 발전을 위해 다양한 노력을 기울인다',
        ],
      },
      {
        name: '대인이해',
        desc: '상대방의 개인적인 특성, 감정과 의도, 또는 어떤 행동의 원인 등을 파악하고 이해한다',
        detail: [
          '상대방의 개인적인 특성이나 장단점을 이해하고 존중한다',
          '상대방의 현재 생각이나 감정, 또는 의도 등을 파악한다',
          '상대방이 취하는 행동이나 태도의 원인이 무엇인지 파악하고 이해한다',
        ],
      },
      {
        name: '이문화수용성',
        desc: '다양한 문화에 대해 호기심을 가지고, 문화적 차이를 존중하며, 다른 문화권에 자연스럽게 적응한다',
        detail: [
          '다른 문화에 대한 호기심을 바탕으로, 지속적으로 관련 정보를 탐색한다',
          '다른 문화를 접할 때 문화적 차이를 존중하고, 상황/환경에 따라 적절하게 행동한다',
          '자신과 문화적 배경(지역, 종교, 언어, 가치관 등)이 다른 사람들과 관계를 잘 형성한다',
        ],
      },
      {
        name: '타인존중',
        desc: '상대방의 생각이나 가치관을 이해하고 배려하며, 지지와 격려의 메시지를 전달한다',
        detail: [
          '상대방의 생각이나 가치관을 이해하고, 이를 존중한다',
          '겸손한 태도와 존중하는 모습으로 상대방에게 예의를 갖추고 배려한다',
          '상대방의 의견이 합당하다고 여겨질 경우, 적절한 지지와 격려의 메시지를 보낸다',
        ],
      },
      {
        name: '팀워크지향',
        desc: '집단의 공동 목표 달성을 위해 적극적으로 협조하고, 팀워크를 촉진하며, 집단 내 갈등 요소를 제거한다',
        detail: [
          '집단의 공동 목표를 달성하기 위해, 정보를 공유하거나 다른 팀원을 돕는 등 적극적으로 협조한다',
          '집단의 공동 목표 달성을 저해하는 행동을 저지하고, 소극적인 팀원의 참여를 촉진한다',
          '집단 내 갈등을 미연에 방지하고, 갈등이 발생하더라도 적절히 해결한다',
        ],
      },
      {
        name: '협조성',
        desc: '다른 사람들의 일에 관심을 갖고, 자발적으로 협력하며, 보다 높은 관점에서 도움이 되는 방안을 모색한다',
        detail: [
          '다른 사람들의 일에 관심을 갖고, 필요한 경우 도움과 조언을 제공한다',
          '자신에게 직접적인 이익이 되지 않더라도, 도움이 필요한 사람을 자발적으로 돕는다',
          '다른 집단에서 도움을 요청할 경우, 보다 높은 관점에서 도움이 되는 방안을 다양하게 모색한다',
        ],
      },
      {
        name: '끈기',
        desc: '한 번 시작한 일은 어려움이 있더라도 쉽게 포기하거나 지치지 않으며, 상당한 노력을 투입한다',
        detail: [
          '한 번 시작한 일은 어려움이 있더라도 쉽게 포기하지 않는다',
          '어려움이 있더라도 자신의 관심과 노력을 꾸준하게 유지한다',
          '목표를 달성하기 위해서라면, 애초의 계획 이상으로 상당한 노력을 투입한다',
        ],
      },
      {
        name: '도전정신',
        desc: '새로운 일에 적극적으로 도전하고, 보다 높은 목표를 설정하며, 실패를 두려워하지 않는다',
        detail: [
          '새로운 일을 두려워하지 않고, 보다 적극적으로 탐색하고 도전한다',
          '현실적인 목표에 안주하지 않고, 보다 이상적이고 높은 목표를 설정한다',
          '자신의 실수나 실패에 대해 책임지기를 두려워하지 않는다',
        ],
      },
      {
        name: '성실성',
        desc: '자기관리를 철저히 하고, 자신에게 주어진 일에 집중하며, 결과 뿐만 아니라 과정까지 성실하게 관리한다',
        detail: [
          '육체적/정신적으로 최적의 상태를 유지하기 위해, 자기관리를 철저히 한다',
          '자신에게 주어진 일을 수행하는 동안, 이와 관련없는 개인적인 행동을 하지 않는다',
          '단기적 성과에 집착하기보다, 일의 절차나 원칙을 준수하는 등 일이 과정관리를 더 중요시한다',
        ],
      },
      {
        name: '열정',
        desc: '자신이 맡은 일에 스스로 강한 동기 부여를 하고, 전력으로 집중하며, 보다 큰 성취를 위해 최선을 다한다',
        detail: [
          '외적 보상과 상관없이 자신이 맡은 일 자체에서 의미와 즐거움을 느껴 몰입한다',
          '자신이 맡은 일에 시간과 노력을 최대한 집중한다',
          '보다 큰 성과 또는 보다 높은 수준에 도달하기 위해 노력한다',
        ],
      },
      {
        name: '원칙준수',
        desc: '지켜야 할 규정이나 원칙을 명확히 인식하고, 불편함이나 어려움을 감수하더라도 원칙을 준수하고자 노력한다',
        detail: [
          '지켜야 할 규정 혹은 원칙을 명확히 인식한다',
          '어떤 상황에서든 규정 혹은 원칙을 지키고자 한다',
          '규정 혹은 원칙을 지킴으로써 발생할 수 있는 불편함이나 어려움을 기꺼이 감수한다',
        ],
      },
      {
        name: '이익중심사고',
        desc: '비용대비 효과성을 중시하고, 이윤창출 기회와 원가절감 방안을 탐색하며, 투입된 자원과 비용을 점검하고 관리한다',
        detail: [
          '의사결정을 할 때, 비용대비 효과성을 우선적으로 고려한다',
          '원가를 절감하거나, 이윤을 향상시킬 수 있는 방안을 제시한다',
          '투입된 자원과 비용이 효과적으로 활용되는지 점검하고 관리한다',
        ],
      },
      {
        name: '자기주도성',
        desc: '다른 사람이 요청(또는 요구)하기 전에 자신이 해야할 할 일을 탐색하고, 스스로 판단하고 결정하며, 이에 따라 책임을 지고 일을 완수한다',
        detail: [
          '다른 사람이 요청(또는 요구)하기 전에 자신이 해야 할 일을 탐색한다',
          '다른 사람에게 의지하지 않고, 스스로 판단하고 결정한다',
          '자신이 설정한 전략과 계획에 따라, 책임을 지고 일을 완수한다',
        ],
      },
      {
        name: '적극성',
        desc: '주변의 문제에 대해 관심을 가지고, 더 많은 역할이라도 기꺼이 수행하며, 자신이 맡은 일은 즉각적으로 처리한다',
        detail: [
          '주변의 문제에 대해 관심을 가지며, 한 발 앞서 적극적으로 대응한다',
          '다른 사람들이 기피하거나, 자신에게 주어진 것 이상의 일이라도 기꺼이 수행한다',
          '자신이 맡은 일을 뒤로 미루지 않고 즉각적으로 처리한다',
        ],
      },
      {
        name: '조직헌신',
        desc: '조직의 미션과 목표 등을 명확히 이해하고, 조직의 결정과 정책을 지지하며, 이에 맞추어 행동한다',
        detail: [
          '조직의 미션과 목표를 명확히 인지하고 이를 내면화 한다',
          '미션과 목표에 부합하는 조직의 결정과 정책을 지지하고 옹호한다',
          '개인적 희생이 따르더라도 조직의 결정과 정책에 맞추어 행동한다',
        ],
      },
      {
        name: '책임감',
        desc: '자신의 일 뿐만 아니라 집단의 일에도 책임을 가지고 최선을 다하며, 그 결과에 대해 책임지려는 자세를 가진다',
        detail: [
          '자신의 일에 책임감을 가지고 끝까지 최선을 다한다',
          '사후 발생한 문제에 대해 스스로 책임지려는 자세를 가진다',
          '다른 집단 구성원의 실수나 실패가 발생해도 함께 이를 극복하려 노력한다',
        ],
      },
      {
        name: '추진력',
        desc: '계획한 일은 반드시 실행으로 옮기며, 어려움이 있더라도 실행계획에 따라 최대한 밀어붙인다',
        detail: [
          '목표를 정확히 파악하고, 이를 위해 필요한 세부과제를 구체화한다',
          '본인이 계획한 일에 대해 소신을 가지고 밀어붙인다',
          '어려움에 처하더라도 일을 중단하기지 않으며, 정해진 기한 내에 목표를 달성해 내기 위해 노력한다',
        ],
      },
      {
        name: '품질지향성',
        desc: '일을 처리하는 과정에서 일의 질적 우수성에 초점이 맞춰져 있으며, 질적 우수성에 대한 높은 기준을 가지고 있다',
        detail: [
          '품질에 영향을 미치는 핵심요소를 파악하고, 이를 꼼꼼하게 점검하고 분석한다',
          '품질과 관련된 지침 및 규정을 파악하고, 이를 지키기 위해 노력한다',
          '보다 나은 품질을 달성하기 위해 주변의 다양한 의견을 반영해 개선안을 제시한다',
        ],
      },
      {
        name: '효율성추구',
        desc: '적은 자원을 투입하여 최대의 성과(산출)를 얻는 것을 중요하게 여기며, 그러한 방법을 지속적으로 탐색한다',
        detail: [
          '의사결정 시, 최소의 비용으로 최대의 성과를 낼 수 있는 효율성 측면을 우선적으로 고려한다',
          '업무 수행 과정에서 활용가능한 자원(사람, 자산, 지원 등)을 효율적으로 활용한다',
          '기존 업무 수행의 비효율적인 부분을 점검하여 이를 개선할 수 있는 방법이나 프로세스를 제안한다',
        ],
      },
    ],
  },
  {
    tab: '직무역량',
    groups: [
      { name: '경영관리', start: 0, count: 5 },
      { name: '소프트웨어개발', start: 5, count: 5 },
      { name: '전산', start: 10, count: 5 },
      { name: '제품 및 기술영업', start: 15, count: 5 },
      { name: '기계공학', start: 20, count: 5 },
      { name: '홍보/마케팅', start: 25, count: 5 },
    ],
    items: [
      {
        name: '전략마인드',
        desc: '경영환경 변화 및 시장 트렌드를 전략적으로 이해하고 조직의 비전을 공유하여 조직발전에 기여한다',
        detail: [
          '자신의 세부직무를 행함에 있어 조직의 중장기 비전을 고려하여 중장기 계획을 수립하고 단계별 실천을 통해 모든 계획과 수행을 사회전반의 다양한 활동과 연계하여 종합적으로 판단하고 실천한다',
          '조직의 현재상황을 잘 이해하고 조직이 나아갈 방향에 대해 적극적으로 동참하여 주도적으로 이끌어 간다',
          '자료수집 능력 및 데이터 가공능력이 뛰어나 정확한 분석자료를 제시한다',
        ],
      },
      {
        name: '경영자원관리',
        desc: '조직이 보유한 인적자원을 효율적으로 활용하여, 조직 내 유ㆍ무형 자산 및 재무자원을 효율적으로 관리한다',
        detail: [
          '환경과 상황에 맞게 경영자원관리를 최적으로 배분 및 활용하여 이를 바탕으로 전사적 자원관리를 가능케 하며 이에 대한 보고능력을 갖추고 있다',
          '경영자원의 효율성을 미리 생각하고 낭비와 불편함이 없도록 한다',
          '조직의 유ㆍ무형 자산에 대한 중요성을 인지하고 작은 것 하나도 소중하게 생각한다',
        ],
      },
      {
        name: '경영개선',
        desc: '경영활동에서 발생하는 여러 문제를 종합적으로 분석하여 실행 가능한 개선안을 제시한다',
        detail: [
          '현행프로세스를 분석하고 경영환경개선을 위해 다양한 아이디어 제시를 통해 앞으로 나아가야 할 방향을 제안한다',
          '업무적 문제의식, 문제 개선에 대한 의지로 여러 가지 업무개선을 시행한다',
          '사소한 것 이라도 호기심을 가지고 문제와 원인을 찾아낸다',
        ],
      },
      {
        name: '내부고객지향',
        desc: '구성원들의 니즈를 정확하게 파악하는 등 원만한 관계를 형성하고, 개인별 팀별 요구사항에 대한 적극적인 수용을 통해 구성원들의 최대 만족을 위해 노력한다',
        detail: [
          '구성원들과 원만한 관계를 유지하고 뛰어난 소통능력을 통해 요구사항을 수용타협하며 주요 경영상황을 적시에 적절한 방법으로 전달한다',
          '내부 고객에 대한 서비스 마인드를 함양하고 구성원들의 만족을 위해 노력한다',
          '내부직원들에게 한마디라도 살갑게 대하려 노력하고 예의를 갖춰 오해가 발생하지 않도록 노력한다',
        ],
      },
      {
        name: '문서정보 전달',
        desc: '문서를 작성함에 있어 논리적으로 구성하고 효과적으로 보고, 발표한다',
        detail: [
          '논리적인 문서작성을 통해 의사전달을 함으로써 구성원 및 경영진을 설득시키고, 문서들의 적절한 관리를 통해 신속 정확한 업무를 진행 할 수 있다',
          '문서작성에 대한 프로세스를 정립하고 매뉴얼 등을 작성하며 이를 표준화시키며 효과적인 문서관리를 한다',
          '문서작성의 목적을 이해하고 자료 보관방법을 안다',
        ],
      },
      {
        name: '사용자지향',
        desc: '사용자(또는 고객)의 요구와 기대를 파악하고 이를 충족시키리 위해 노력한다',
        detail: [
          '사용자(또는 고객)의 특성과 기술적 요구사항을 파악한다',
          '사용자의 입장에서 보다 쉽고 편하게 소프트웨어를 활용할 수 있는 방법을 찾기 위해 노력한다',
          '개발이 완료된 후에도, 사용자의 불편사항을 파악하기 위해 노력한다',
        ],
      },
      {
        name: '트렌드 파악',
        desc: '소프트웨어와 관련된 신기술이나 최신 트렌드를 파악하고, 개발 시 이를 적극적으로 활용∙응용한다',
        detail: [
          '다양한 방법과 채널을 통해 자신의 분야와 관련된 신기술을 파악하고 익히기 위해 노력한다',
          '자신의 분야에 영향을 미치는 산업환경(제도, 규제, 소비자트렌드 등)의 변화를 파악한다',
          '신기술이나 산업환경변화에 맞게 기존의 기술 또는 개발된 제품을 응용하고 변화시킨다',
        ],
      },
      {
        name: '세밀한 업무처리',
        desc: '계획적∙체계적으로 업무를 처리하여 일정지연이나 불량∙오류를 방지한다',
        detail: [
          '소프트웨어 개발 중이나 개발완료 후에 문제가 발생할 수 있는 부분을 미연에 발견하고 대처한다',
          '해결과제 및 업무처리 프로세스 등을 사전에 철저히 계획하여 일정이 지연되지 않도록 한다',
          '개발 중, 불량이나 오류가 발생하지 않도록 세밀하게 업무를 처리한다',
        ],
      },
      {
        name: '커뮤니케이션',
        desc: '상대방이 말하고자 하는 것을 정확히 파악하고, 내가 말하고자 하는 것을 정확히 전달하여 타인과 효과적으로 소통한다',
        detail: [
          '상대방의 특성을 고려하고 의견을 경청하여 입장을 명확히 파악한다',
          '구두 또는 문서를 통해 자신의 의사를 분명하게 전달한다',
          '이견이 발생할 경우 상대방(또는 양측)의 입장을 명확히 파악하고 조정한다',
        ],
      },
      {
        name: '협조성',
        desc: '조직 목표 달성을 위해 타 부서나 팀(원)들과 정보, 업무, 책임, 의사결정 등을 적극적으로 공유하고 협력한다',
        detail: [
          '팀의 공동 업무에 적극적으로 참여하며, 주변 동료들의 업무에 관심을 가진다',
          '타 부서나 팀원들의 업무와 자신의 업무간 상호연관성을 인식하고, 도움이 필요할 시 적극적으로 지원한다',
          '조직 전체의 업무 수행에 도움이 되는 다양한 정보를 적극적으로 공유한다',
        ],
      },
      {
        name: '비판적 사고',
        desc: '어떤 현상에 문제의식을 가지고 객관적으로 다양한 관점에서 바라본다.',
        detail: [
          '기존의 방식이나 방법에 대해 문제의식을 갖고 다른 관점에서 사고한다',
          '자신의 문제해결 방식에 존재할 수 있는 편향성이나 오류를 검토한다',
          '근거가 부족한 부분을 찾아내고, 합리적 판단을 위해 더 필요한 증거가 무엇인지 탐색한다',
        ],
      },
      {
        name: '분석적 사고',
        desc: '상황, 정보, 과제, 문제 등을 보다 세분화하여 이해하며 핵심 사항을 파악한다',
        detail: [
          '다양한 방법과 기법들을 활용하여 복잡한 문제와 과정을 체계적으로 분해한다',
          '제시된 자료들을 정략적 및 정성적인 측면에서 파악하여 정확한 결론을 도출해낸다',
          '제시된 상황, 정보들을 체계적으로 비교하여 유사점, 차이점을 파악한다',
        ],
      },
      {
        name: '전문성',
        desc: '자신의 전문 분야와 관계된 일정한 지식이나 정보를 보유하고 있으며, 이를 업무에 활용 및 적용할 수 있다',
        detail: [
          '과업의 기술적, 절차적인 면을 정확히 이해하고 있다',
          '과업 목표를 달성하는 데 있어서 자신이 지닌 전문지식을 활용한다',
          '자신의 전문 분야와 관계된 최신 정보나 지식 습득에 열의를 보인다',
        ],
      },
      {
        name: '품질지향성',
        desc: '일을 처리하는 과정에서 일의 질적 우수성에 초점이 맞춰져 있으며, 질적 우수성에 대한 높은 기준을 가지고 있다',
        detail: [
          '결과의 우수성(질)에 관한 기준이 다른 사람보다 높다',
          '기존 결과보다 더 우수한 결과를 산출하기 위해 노력한다',
          '양적인 측면보다 질적인 측면에 초점을 맞추고 일을 추진해내간다',
        ],
      },
      {
        name: '프로세스 지향성',
        desc: '업무 처리 과정과 그에 따르는 결과 간 관계를 고려하여 전체 업무 프로세스를 설정하고, 이에 따라 업무를 처리한다.',
        detail: [
          '일을 처리함에 있어 정해진 프로세스를 따른다',
          '각 프로세스 별 의미와 그것이 결과에 미치는 영향을 파악한다.',
          '보다 효율적이고 효과적인 방식으로 프로세스를 개선하려 노력한다.',
        ],
      },
      {
        name: '전문성',
        desc: '자신의 전문 분야와 관계된 일정한 지식이나 정보를 보유하고 있으며, 이를 업무에 활용 및 적용할 수 있다',
        detail: [
          '과업의 기술적, 절차적인 면을 상항에 맞게 정확히 적용하고, 유용한 상황을 항상 염두에 둔다',
          '과업 목표를 달성하는 데 있어서 자신이 지닌 전문지식을 활용한다',
          '자신의 전문 분야와 관계된 최신 정보나 지식 습득에 열의를 보인다',
        ],
      },
      {
        name: '이해력',
        desc: '어떤 현상이나 정보의 의미나 의도를 빠르게 파악한다',
        detail: [
          '현상이나 정보의 의미나 의도를 정확히 파악한다',
          '현상이나 정보에서 드러나지 않은 숨겨진 의미까지 파악한다',
          '자신이 해석한 내용을 적용 또는 수정할 수 있다',
        ],
      },
      {
        name: '분석적 사고',
        desc: '상황, 정보, 과제, 문제 등을 보다 세분화하여 이해하며 핵심 사항을 파악한다',
        detail: [
          '다양한 방법과 기법들을 활용하여 복잡한 문제와 과정을 체계적으로 분해한다',
          '제시된 자료들을 정략적 및 정성적인 측면에서 파악하여 정확한 결론을 도출해낸다',
          '제시된 상황, 정보들을 체계적으로 비교하여 유사점, 차이점을 파악한다',
        ],
      },
      {
        name: '목표설정 및 관리',
        desc: '명확한 목표와 성과기준을 설정하고, 묙표 달성을 체계적으로 관리한다',
        detail: [
          '목표를 어떻게 설정하는 지에 대한 방법과 프로세스를 인식하고 있다',
          '목표설정을 위해 성과에 영향을 미치는 내외부 환경요인을 파악하고 있다',
          '설정한 목표 달성 과정에 대한 점검을 통해 목표 달성 저해 요소를 제거하거나 목표를 수정한다',
        ],
      },
      {
        name: '변화대응 및 적응',
        desc: '상황이나 환경의 변화를 능동적으로 받아들이고, 유연하게 대처하여 변화에 신속히 적응할 수 있다',
        detail: [
          '급격한 환경변화에도 회피하지 않고 능동적으로 대응한다',
          '변화로 생긴 새로운 방법과 절차에 대한 깊은 이해를 추구한다',
          '과업활동과 과업목표를 달성하는 새로운 방법을 습득함에 적극성을 보인다',
        ],
      },
      {
        name: '분석적 사고',
        desc: '상황, 정보, 과제, 문제 등을 보다 세분화하여 이해하며 핵심 사항을 파악한다',
        detail: [
          '다양한 방법과 기법들을 활용하여 복잡한 문제와 과정을 체계적으로 분해한다',
          '제시된 자료들을 정략적 및 정성적인 측면에서 파악하여 정확한 결론을 도출해낸다',
          '제시된 상황, 정보들을 체계적으로 비교하여 유사점, 차이점을 파악한다',
        ],
      },
      {
        name: '논리적 사고',
        desc: '상황이나 정보들의 연관성이나 인과 관계를 파악하며 명시되지 않은 관련성까지 파악해낼 수 있다',
        detail: [
          '복잡한 정보 및 이슈들간의 관계성을 파악해낸다',
          '타당한 근거를 바탕으로 판단을 내린다',
          '연쇄적으로 결론이나 함의를 도출할 수 있다',
        ],
      },
      {
        name: '전문성',
        desc: '자신의 전문 분야와 관계된 일정한 지식이나 정보를 보유하고 있으며, 이를 업무에 활용 및 적용할 수 있다',
        detail: [
          '과업의 기술적, 절차적인 면을 정확히 이해하고 있다',
          '과업 목표를 달성하는 데 있어서 자신이 지닌 전문지식을 활용한다',
          '자신의 전문 분야와 관계된 최신 정보나 지식 습득에 열의를 보인다',
        ],
      },
      {
        name: '품질지향성',
        desc: '일을 처리하는 과정에서 일의 질적 우수성에 초점이 맞춰져 있으며, 질적 우수성에 대한 높은 기준을 가지고 있다',
        detail: [
          '기존 결과보다 더 우수한 결과를 산출하기 위해 노력한다',
          '양적인 측면보다 질적인 측면에 초점을 맞추고 일을 추진해내간다',
          '결과의 우수성(질)에 관한 기준이 다른 사람보다 높다',
        ],
      },
      {
        name: '세밀한 일처리',
        desc: '추진하는 일과 관련하여 사소한 부분까지 모든 부분을 철저하게 검토하고 확인하여 정확하고 치밀하게 일을 처리한다',
        detail: [
          '일을 추진해내가는 과정에서 사소한 일이라도 놓치지 않도록 체크리스트, 스케쥴표, 일정표 등을 잘 관리한다',
          '오류가 발생하지 않도록 다양한 노력을 기울인다',
          '각종 정보나 데이터의 정확성을 이중으로 확인한다',
        ],
      },
      {
        name: '추진력',
        desc: '맡은 업무를 추진하는 과정에서 보다 더 좋은 결과를 얻기 위해 다양한 노력을 기울이며 끊임없이 노력한다',
        detail: [
          '업무를 성공적으로 완수하기 위해 다양한 방법을 시도한다',
          '일이 순조롭게 풀리지 않더라도 쉽게 포기하지 않는다',
          '새로운 방법이나 정보를 습득함에 있어 적극성을 보인다',
        ],
      },
      {
        name: '전략수립',
        desc: '주어진 상황/과제/문제를 보다 세부적인 단위로 분해하여 분석하고, 단계적인 방법으로 상황/과제/문제의 의미를 파악한다',
        detail: [
          '복잡한 과제를 체계적 방법으로 처리할 수 있는 작은 단위로 세분화한다',
          '마케팅 업무 추진 시 중요도를 고려하여 우선순위를 설정한다',
          '패턴이나 동향 파악을 위해 조사에서 나온 양적, 질적인 자료를 비교, 종합하여 정확한 결론을 도출해낸다',
        ],
      },
      {
        name: '업무협력관계구축',
        desc: '업무와 관련된 목표를 달성하는 데 도움이 되거나 앞으로 도움이 될지도 모르는 사람들과 친밀한 관계를 구축한다',
        detail: [
          '다른 사람과 정보를 제공하고 도움을 주고 받을 수 있는 상황에서 항상 적극적이다',
          '자신의 업무와 관련된 부문을 확인하고 상호 협동의 필요성을 파악한다',
          '동료의 중요한 목표 달성을 돕기 위해 자신의 시간과 자원을 기꺼이 제공한다',
        ],
      },
      {
        name: '효과적 의사전달',
        desc: '마케팅 직무를 추진하면서 공유되는 정보들, 공동 확인 사항들을 효과적으로 전달한다',
        detail: [
          '복잡한 정보를 조직화하여 아이디어를 명확하고 간결하게 전달한다',
          '자신이 전달하고자 하는 요지를 명확하게 인식하고 있다',
          '공식적/비공식적 자리에서 자신의 입장을 효과적으로 전달하기 위한 전략을 구사한다',
        ],
      },
      {
        name: '창의적 아이디어 제시',
        desc: '새롭고 개선된 방식의 필요성을 인식하고 새로운 업무 아이디어를 얻고자 항상 노력한다',
        detail: [
          '기존의 업무방식에 대해 비판적인 시각으로 접근하여 새로운 아이디어를 제시한다',
          '주어진 과업을 완수함에 있어 효과적이고 효율적인 방법을 모색하고, 좀 더 나은 방법을 추구한다',
          '더 나은 해결책을 위해 계산될 수 있는 약간의 위험도 감수한다',
        ],
      },
    ],
  },
];

// https://csvjson.com/csv2json
// let data = [
//     { tab: "1", groups: [], items: [] },
//     { tab: "2", groups: [], items: [] },
//     { tab: "3", groups: [], items: [] },
//   ];
//   for (let i = 0; i < 18; i++) {
//     let item = {
//       name: d[1 + i * 3]['__4'],
//       desc: d[1 + i * 3]['__5'],
//       detail: [
//         d[1 + i * 3]['__6'], d[2 + i * 3]['__6'], d[3 + i * 3]['__6']
//       ]
//     }
//     data[0].items.push(item)
//   }

//   for (let i = 0; i < 30; i++) {
//     let item = {
//       name: d[55 + i * 3]['__4'],
//       desc: d[55 + i * 3]['__5'],
//       detail: [
//         d[55 + i * 3]['__6'], d[56 + i * 3]['__6'], d[57 + i * 3]['__6']
//       ]
//     }
//     data[1].items.push(item)
//   }

//   for (let i = 0; i < 30; i++) {
//     let item = {
//       name: d[145 + i * 3]['__4'],
//       desc: d[145 + i * 3]['__5'],
//       detail: [
//         d[145 + i * 3]['__6'], d[146 + i * 3]['__6'], d[147 + i * 3]['__6']
//       ]
//     }
//     data[2].items.push(item)
//   }

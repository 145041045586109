<div class="flex h-full w-full gap-[4px]">
  <div class="flex min-w-0 flex-auto flex-col rounded-m bg-neutral100">
    <div class="h-[56px]">
      <div class="m-[16px] select-none text-title-m text-neutral0">
        검색 결과
      </div>
    </div>
    <div class="relative mb-[16px] flex-auto">
      <app-table
        [datas]="datas"
        [columns]="columns"
        [tabs]="tabs"
        [tableEvent]="tableEvent"
        [options]="options"
        deleteButton='<div class="select-none cursor-pointer text-label-l text-red items-center flex h-[36px] gap-[6px] border-[1.6px] border-red rounded-m ps-[8px] pe-[16px]"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.40625 20.5391C7.125 20.5391 6.33594 19.8125 6.27344 18.5391L5.75 7.36719H4.95312C4.51562 7.36719 4.16406 7.03125 4.16406 6.58594C4.16406 6.14844 4.51562 5.8125 4.95312 5.8125H8.33594V4.66406C8.33594 3.41406 9.125 2.6875 10.5078 2.6875H13.4844C14.8594 2.6875 15.6484 3.41406 15.6484 4.66406V5.8125H19.0469C19.4922 5.8125 19.8359 6.14844 19.8359 6.58594C19.8359 7.03125 19.4922 7.36719 19.0469 7.36719H18.25L17.7266 18.5312C17.6719 19.8047 16.8672 20.5391 15.6016 20.5391H8.40625ZM9.96875 4.72656V5.8125H14.0234V4.72656C14.0234 4.35938 13.7656 4.125 13.3672 4.125H10.625C10.2188 4.125 9.96875 4.35938 9.96875 4.72656ZM8.60156 18.9688H15.3906C15.8203 18.9688 16.1094 18.6719 16.125 18.2031L16.6172 7.36719H7.35938L7.875 18.2031C7.89062 18.6641 8.17969 18.9688 8.60156 18.9688ZM9.70312 17.7734C9.36719 17.7734 9.14062 17.5469 9.125 17.2109L8.89062 9.17969C8.88281 8.83594 9.10938 8.60938 9.46094 8.60938C9.80469 8.60938 10.0469 8.83594 10.0547 9.17188L10.2891 17.2031C10.2969 17.5391 10.0703 17.7734 9.70312 17.7734ZM12 17.7734C11.6484 17.7734 11.4141 17.5469 11.4141 17.2109V9.17188C11.4141 8.83594 11.6484 8.60938 12 8.60938C12.3516 8.60938 12.5859 8.83594 12.5859 9.17188V17.2109C12.5859 17.5469 12.3516 17.7734 12 17.7734ZM14.2969 17.7734C13.9297 17.7734 13.7031 17.5391 13.7109 17.2031L13.9453 9.17188C13.9531 8.83594 14.1953 8.61719 14.5391 8.61719C14.8906 8.61719 15.1172 8.84375 15.1094 9.17969L14.875 17.2109C14.8594 17.5469 14.6328 17.7734 14.2969 17.7734Z" fill="#FF3B30"/></svg>내보내기</div>'
        (event)="onEvent($event)"
      ></app-table>
    </div>
  </div>
</div>

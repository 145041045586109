import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { AjaxService } from '../../core/services/ajax.service';
import { ClientService } from '../../core/services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-join-mail-interviewer',
  templateUrl: './join-mail-interviewer.component.html',
  styleUrl: './join-mail-interviewer.component.scss',
})
export class JoinMailInterviewerComponent {
  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  ajax = inject(AjaxService);
  client = inject(ClientService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  sanitizer = inject(DomSanitizer);

  username = '';
  password = '';

  step = 0;

  passwordError = true;

  clientData = {
    name: '',
    profile: '',
  };
  auth = '';

  async ngOnInit() {
    const auth = this.route.snapshot.paramMap.get('auth');
    if (auth) {
      this.auth = auth;
      const r = await this.ajax.post('auth/checkAuthInvite', { auth });
      const res = r.data.datas;
      if (res.length > 0) {
        this.username = res[0].username;
        // const clientId = res[0].client;
        // const [clientRes] = await this.ajax.query(
        //   `SELECT name, profile FROM clients WHERE id = ?`,
        //   [clientId],
        // );
        // console.log(clientRes);
        this.clientData = r.data.clientData;
      } else {
        this.client.setToast({
          data: '유효하지 않은 인증 코드 입니다.',
        });
        this.router.navigate(['']);
      }
    } else {
      this.client.setToast({
        data: '유효하지 않은 인증 코드 입니다.',
      });
      this.router.navigate(['']);
    }
  }

  async checkValidEmail() {
    return;
    const validateEmail = (email: string) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    };

    if (!validateEmail(this.username)) {
      this.client.setToast({
        bg: 'red',
        data: '이메일이 유효하지 않습니다.',
      });
      return;
    }

    const [res] = await this.ajax.query(
      `SELECT COUNT(*) as cnt FROM interviewers WHERE username = ?`,
      [this.username],
    );
    console.log(res);
    if (res.cnt > 0) {
      this.client.setToast({
        bg: 'red',
        data: '이미 존재하는 이메일입니다.',
      });
    }
  }

  checkPassword() {
    const hasUpperCase = (str: string) => {
      return /[A-Z]/.test(str);
    };
    const hasLowerCase = (str: string) => {
      return /[a-z]/.test(str);
    };
    const hasNumber = (str: string) => {
      return /\d/.test(str);
    };
    const hasSpecialCharacter = (str: string) => {
      return /[!@#$%^&*(),.?":{}|<>]/.test(str);
    };

    let count = 0;
    if (hasUpperCase(this.password)) count += 1;
    if (hasLowerCase(this.password)) count += 1;
    if (hasNumber(this.password)) count += 1;
    if (hasSpecialCharacter(this.password)) count += 1;

    return count >= 2 && this.password.length >= 8;
  }

  async emailEnter() {
    if (this.step === 0) {
      this.step = 1;
      await this.checkValidEmail();
    }
    setTimeout(() => {
      this.passwordInput.nativeElement.focus();
    }, 0);
  }

  async submit() {
    if (this.step === 0) {
      this.step = 1;
      await this.checkValidEmail();
    } else if (this.step === 1) {
      if (!this.checkPassword()) {
        this.passwordError = true;
        this.client.setToast({
          bg: 'red',
          data: '비밀번호가 유효하지 않습니다.',
        });
        return;
      }

      await this.ajax.post('auth/changeInvitePasswordAction', {
        auth: this.auth,
        password: this.password,
      });

      this.client.setToast({
        data: '인증이 완료되어 서비스 이용이 가능합니다.',
      });

      const loginRes = await this.ajax.post('login', {
        username: this.username,
        password: this.password,
      });

      console.log(loginRes);

      if (loginRes.error === 0) {
        const res = loginRes.data;
        if (res.emailAuth === 0) {
          this.router.navigate(['email', this.username]);
        } else {
          this.client.token = res.token;
          localStorage.setItem('token', res.token);
          this.router.navigate(['interview']);
        }
      } else {
        this.client.setToast({
          bg: 'red',
          data: '잘못된 이메일 또는 비밀번호입니다.',
        });
      }
    }
  }
}

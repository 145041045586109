<div
  class="fixed left-0 top-0 h-screen w-screen"
  style="z-index: 999998"
  (click)="
    event.emit({
      id,
      data: 'cancel'
    })
  "
></div>
<div
  class="fixed left-1/2 top-1/2 z-50 min-h-[100px] min-w-[320px] -translate-x-1/2 -translate-y-1/2 rounded-m bg-neutral100 shadow-s"
  style="z-index: 999999"
>
  <div class="flex items-center py-[14px] pe-[12px] ps-[16px]">
    <div
      class="me-[8px] flex h-[24px] w-[24px] items-center justify-center"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(titleIcon)"
      *ngIf="titleIcon"
    ></div>
    <div class="flex-auto text-title-m text-neutral0">{{ title }}</div>
    <div
      class="flex h-[28px] w-[28px] cursor-pointer items-center justify-center"
      (click)="
        event.emit({
          id,
          data: 'cancel'
        })
      "
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.44141 13.334C4.14844 13.0469 4.16016 12.5488 4.43555 12.2734L7.92773 8.77539L4.43555 5.2832C4.16016 5.00781 4.1543 4.51562 4.44141 4.22266C4.72852 3.92969 5.22656 3.94141 5.50781 4.2168L9 7.70898L12.4863 4.2168C12.7734 3.92969 13.2598 3.93555 13.5527 4.22266C13.8457 4.51562 13.8457 5.00195 13.5586 5.28906L10.0723 8.77539L13.5586 12.2676C13.8457 12.5547 13.8398 13.041 13.5527 13.334C13.2656 13.627 12.7734 13.627 12.4863 13.3398L9 9.84766L5.50781 13.3398C5.22656 13.6152 4.73438 13.627 4.44141 13.334Z"
          fill="#0B0C0F"
        />
      </svg>
    </div>
  </div>
  <div class="mx-[16px] h-[1px] bg-neutral90"></div>
  <div
    class="px-[16px] pb-[24px] pt-[16px] text-body-l text-neutral0"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(body)"
  ></div>
  <div
    class="mx-[16px] mb-[16px] mt-[-12px] rounded-s bg-lightblue px-[16px] py-[9px] text-body-m text-blue"
    *ngIf="desc"
    [innerHTML]="sanitizer.bypassSecurityTrustHtml(desc)"
  ></div>
  <div class="mb-[16px] flex justify-end gap-[8px] pe-[16px]">
    <div
      class="flex h-[36px] cursor-pointer select-none items-center rounded-m border-[1.6px] border-neutral0 px-[24px] py-[6px] text-label-l text-neutral0"
      *ngIf="buttonCancel"
      (click)="
        event.emit({
          id,
          data: 'cancel'
        })
      "
    >
      {{ buttonCancel }}
    </div>
    <div
      class="flex h-[36px] cursor-pointer select-none items-center rounded-m bg-neutral0 px-[24px] py-[6px] text-label-l text-neutral100"
      *ngIf="buttonSubmit"
      (click)="
        event.emit({
          id,
          data: 'submit'
        })
      "
    >
      {{ buttonSubmit }}
    </div>
  </div>
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-input',
  templateUrl: './modal-input.component.html',
  styleUrl: './modal-input.component.scss',
})
export class ModalInputComponent {
  @ViewChild('inputDiv') inputDiv!: ElementRef<HTMLDivElement>;
  @Input() data: any = {};
  @Input() inputEvent!: EventEmitter<any>;
  @Output() event = new EventEmitter();

  id = '';
  title = '';
  titleIcon = '';
  body = '';
  buttonCancel = '';
  buttonSubmit = '';

  text = '';
  placeholder = '';

  sanitizer = inject(DomSanitizer);

  inputError = false;

  cb: any = {
    inputEvent: null,
  };

  onKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      this.event.emit({
        id: this.id,
        data: 'cancel',
      });
    }
  };

  ngOnInit() {
    let {
      id,
      title,
      titleIcon,
      body,
      buttonCancel,
      buttonSubmit,
      text,
      placeholder,
    } = this.data;
    this.id = id;
    this.title = title;
    this.titleIcon = titleIcon;
    this.body = body;
    this.buttonCancel = buttonCancel;
    this.buttonSubmit = buttonSubmit;
    if (text) {
      this.text = text;
    }
    if (placeholder) {
      this.placeholder = placeholder;
    }

    setTimeout(() => {
      this.body, this.inputDiv.nativeElement.focus();
    }, 0);

    window.addEventListener('keydown', this.onKeyDown);

    if (this.inputEvent) {
      this.cb.inputEvent = this.inputEvent.subscribe((e: any) => {
        if (e.id === 'inputError') {
          this.inputError = true;
        }
      });
    }
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
    if (this.inputEvent) {
      this.cb.inputEvent.unsubscribe();
    }
  }
}
